<template>
  <div>
    <!-- Main section -->
    <div
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="text-align: left; margin-left: 26px; margin-right: 26px">
        <div class="pt-4 ma-3" style="font-size: 20px; color: #444444">
          <v-icon large left> mdi-printer </v-icon>
          <span>Print E-Tax Invoice</span>

          <v-card class="v-card-panel" elevation="1">
            <v-card elevation="10" class="v-card-panel" width="500">
              <v-card-title>ข้อมูลผู้ขอใบกำกับภาษี </v-card-title>
              <v-card-subtitle
                >Tax invoice requester information</v-card-subtitle
              >
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model="form.tax_invoice_no"
                    label="เลขกำกับภาษี / Tax invoice no. *"
                    required
                    clearable
                    filled
                    dense
                    clear-icon="mdi-close-circle"
                    :rules="[(v) => !!v || 'กรุณากรอกเลขกำกับภาษี']"
                  ></v-text-field>

                  <v-text-field
                    v-model="form.name"
                    label="ชื่อ-นามสกุล / Full name *"
                    required
                    clearable
                    filled
                    dense
                    clear-icon="mdi-close-circle"
                    :rules="[(v) => !!v || 'กรุณากรอกชื่อ-นามสกุล']"
                  ></v-text-field>

                  <v-text-field
                    v-model="form.tax_ID"
                    label="เลขประจำตัวผู้เสียภาษี / Tax ID *"
                    required
                    clearable
                    filled
                    dense
                    clear-icon="mdi-close-circle"
                    :rules="[(v) => !!v || 'กรุณากรอกเลขประจำตัวผู้เสียภาษี']"
                    :counter="13"
                  ></v-text-field>

                  <v-textarea
                    v-model="form.address"
                    label="ที่อยู่ / Address *"
                    required
                    clearable
                    filled
                    dense
                    clear-icon="mdi-close-circle"
                    :rules="[(v) => !!v || 'กรุณากรอกที่อยู่']"
                    rows="2"
                  ></v-textarea>

                  <v-btn class="mr-4" @click="previewETaxInvoice()">
                    <v-icon left> mdi-eye </v-icon>
                    Preview
                  </v-btn>
                  <v-btn
                    class="mr-4"
                    color="primary"
                    @click="generateETaxInvoice()"
                  >
                    <v-icon left dark> mdi-printer </v-icon>
                    Print
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-card>
        </div>
      </div>
    </div>

    <!-- Preview PDF dialog -->
    <v-dialog v-model="dialogPreviewPDF" max-width="800px" persistent>
      <v-card>
        <v-card-title class="headline">E-Tax Invoice Preview</v-card-title>
        <v-card-text>
          <div style="position: relative">
            <iframe
              :src="pdfPreviewUrl"
              type="application/pdf"
              class="pdf-Preview"
            ></iframe>
            <div @click.prevent @contextmenu.prevent class="pdf-overlay"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogPreviewPDF = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading dialog -->
    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";
import { generatePDF } from "@/plugins/generateETaxInvoice.js";

export default {
  name: "ETaxInvoice",

  data() {
    const defaultForm = Object.freeze({
      save_log_mode: false,
      tax_invoice_no: "",
      name: "",
      tax_ID: "",
      address: "",
    });

    return {
      url: enurl.apiUrl,
      urlCarpark: enurl.apiCarparkUrl,
      loadingDialog: false,

      // Customer input form
      form: Object.assign({}, defaultForm),
      defaultForm,

      // PDF file
      dialogPreviewPDF: false,
      pdfPreviewUrl: null,
    };
  },

  mounted() {
    let permission = localStorage.getItem("Permision");
    if (permission == null || permission == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(permission).find(
      (x) => x.appName == page
    );
  },

  methods: {
    async previewETaxInvoice() {
      let that = this;
      that.loadingDialog = true;
      if (that.$refs.form.validate()) {
        that.pdfPreviewUrl = "";
        that.$refs.form.resetValidation();

        that.form.save_log_mode = false;
        const [status, data_pdf] = await that.getDataForETaxInvoice(that.form);
        if (status) {
          const pdfDoc = await generatePDF(data_pdf, that.form.save_log_mode);
          const pdfBytes = await pdfDoc.save();
          that.pdfPreviewUrl =
            URL.createObjectURL(
              new Blob([pdfBytes], { type: "application/pdf" })
            ) + "#toolbar=0";

          that.loadingDialog = false;
          that.dialogPreviewPDF = true;
        }
      } else {
        that.loadingDialog = false;
      }
    },

    async generateETaxInvoice() {
      let that = this;
      that.loadingDialog = true;
      if (that.$refs.form.validate()) {
        that.$refs.form.resetValidation();

        that.form.save_log_mode = true;
        const [status, data_pdf] = await that.getDataForETaxInvoice(that.form);
        if (status) {
          const pdfDoc = await generatePDF(data_pdf, that.form.save_log_mode);
          const pdfBytes = await pdfDoc.save();
          const pdfUrl = URL.createObjectURL(
            new Blob([pdfBytes], { type: "application/pdf" })
          );

          window.open(pdfUrl, "_blank");
        }
      }
      that.loadingDialog = false;
    },

    async getDataForETaxInvoice(data) {
      let that = this;
      that.loadingDialog = true;
      let data_pdf = null;
      let status = false;

      await axios
        .post(`${that.urlCarpark}Redemption/GetDataETaxInvoice`, data)
        .then(function (response) {
          if (response.data.status == 0) {
            data_pdf = response.data.data;
            status = true;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });

      return [status, data_pdf];
    },
  },
};
</script>

<style>
.v-card-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}

.pdf-Preview {
  width: 100%;
  height: 500px;
  border: none;
  cursor: not-allowed;
}

.pdf-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
