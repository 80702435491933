<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <v-row style="margin-top: 1px">
          <v-col cols="12" md="6">
            <div
              class="pt-8"
              style="
                font-size: 20px;
                margin-left: 1rem;
                margin-right: 1rem;
                color: #444444;
              "
            >
              Beacons Management
            </div>
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3" style="text-align: right" class="mt-2">
            <!-- <v-select
              outlined
              :items="TowerList"
              label="Tower"
              item-text="towerName"
              item-value="towerID"
              v-model="TowerID"
              @change="getBeaconListByTowerID(TowerID)"
            ></v-select> -->
          </v-col>
        </v-row>

        <v-card-text>
          <div>
            <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="2" class="pt-5">
                  <v-select
                    :items="ZoneList"
                    label="Zone"
                    v-model="ZoneID"
                    required
                    item-value="zoneID"
                    item-text="zoneName"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2" class="pt-5">
                  <v-select
                    :items="FloorList"
                    label="Floor"
                    v-model="FloorID"
                    item-value="floorID"
                    item-text="floorName"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2" class="pt-5">
                  <v-select
                    :items="ProjectList"
                    label="Project"
                    v-model="ProjectID"
                    item-value="projectID"
                    item-text="projectName"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="pt-5">
                  <v-select
                    :items="TowerList"
                    label="Tower"
                    item-text="towerName"
                    item-value="towerID"
                    v-model="TowerID"
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="4">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchBeacon(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="SearchgetBeaconList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenBeaconDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="BeaconList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.beaconName }}</td>
                    <td class="header-table-css">{{ item.beaconMajorCode }}</td>
                    <td class="header-table-css">{{ item.beaconMinorCode }}</td>
                    <td class="header-table-css">{{ item.towerName }}</td>
                    <td class="header-table-css">{{ item.zoneName }}</td>
                    <td class="header-table-css">{{ item.floorName }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 100px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenBeaconDialog('Edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveBeaconByBeaconID(item.beaconID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="BeaconDialog" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Beacon</span>
          <span v-else class="text-h5">Create Beacon</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="BeaconName*"
                  v-model="BeaconName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="MajorCode*"
                  v-model="MajorCode"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="MinorCode*"
                  v-model="MinorCode"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  :items="LocationList"
                  label="Location ID"
                  item-text="locationID"
                  item-value="locationID"
                  v-model="Location"
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-autocomplete
                  :items="LocationList"
                  label="Location Name"
                  item-text="locationName"
                  item-value="locationID"
                  v-model="Location"
                  required
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2"></v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  v-model="Active"
                  hide-details
                  inset
                  :label="Active ? 'Active' : 'InActive'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="ManageBeacon('Create')"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="ManageBeacon('Edit')"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseBeaconDialog()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "BeaconManage",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20, 50, 100],
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      TowerList: [],
      LocationList: [],
      TowerID: null,
      BeaconList: [],
      FloorList: [],
      ZoneList: [],
      ProjectList: [],
      ZoneID: null,
      FloorID: null,
      ProjectID: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      activeSearch: null,
      BeaconDialog: false,
      EditStatus: false,

      // model Add/Edit
      BeaconID: null,
      BeaconName: "",
      MajorCode: "",
      MinorCode: "",
      Location: null,
      Active: null,
      permissionsPage: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "BeaconName",
          align: "left",
          sortable: false,
        },
        {
          text: "MajorCode",
          align: "left",
          sortable: false,
        },
        {
          text: "MinorCode",
          align: "left",
          sortable: false,
        },
        {
          text: "TowerName",
          align: "left",
          sortable: false,
        },
        {
          text: "ZoneName",
          align: "left",
          sortable: false,
        },
        {
          text: "FloorName",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    await self.getBeaconTower();
    await self.getBeaconLocation();
    await self.getBeaconList();
    await self.getFloorList();
    await self.getZoneList();
    await self.getProjectList();
  },

  methods: {

    async SearchgetBeaconList() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search.trim(),
        zoneID: self.ZoneID,
        floorID: self.FloorID,
        projectID: self.ProjectID,
        towerID: self.TowerID,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      await axios
        .post(`${self.url}Beacon/getBeaconList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async getBeaconList() {
      let self = this;
      self.LoadingDialog = true;
      // self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        zoneID: self.ZoneID,
        floorID: self.FloorID,
        projectID: self.ProjectID,
        towerID: self.TowerID,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      await axios
        .post(`${self.url}Beacon/getBeaconList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        zoneID: self.ZoneID,
        floorID: self.FloorID,
        projectID: self.ProjectID,
        towerID: self.TowerID,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Beacon/getBeaconList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        zoneID: self.ZoneID,
        floorID: self.FloorID,
        projectID: self.ProjectID,
        towerID: self.TowerID,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Beacon/getBeaconList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchBeacon(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        zoneID: self.ZoneID,
        floorID: self.FloorID,
        projectID: self.ProjectID,
        towerID: self.TowerID,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Beacon/getBeaconList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    async getBeaconTower() {
      let self = this;
      await axios
        .get(`${self.url}Beacon/getBeaconTower`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TowerList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getFloorList() {
      let self = this;
      await axios
        .get(`${self.url}Beacon/getFloorList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.FloorList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getZoneList() {
      let self = this;
      await axios
        .get(`${self.url}Beacon/getZoneList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ZoneList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getProjectList() {
      let self = this;
      axios
        .get(`${self.url}Beacon/getProjectList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProjectList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getBeaconLocation() {
      let self = this;
      await axios
        .get(`${self.url}Beacon/getBeaconLocation`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LocationList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getBeaconListByTowerID(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        towerID: data,
      };
      axios
        .post(`${self.url}Beacon/getBeaconListByTowerID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BeaconList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    OpenBeaconDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.BeaconDialog = true;
      } else {
        self.BindingEditData(data);
      }
    },

    BindingEditData(data) {
      let self = this;
      self.BeaconID = data.beaconID;
      self.BeaconName = data.beaconName;
      self.MajorCode = data.beaconMajorCode;
      self.MinorCode = data.beaconMinorCode;
      self.Location = data.locationID;
      self.Active = data.active;
      self.EditStatus = true;
      self.BeaconDialog = true;
    },

    CloseBeaconDialog() {
      let self = this;
      self.BeaconName = "";
      self.MinorCode = "";
      self.MajorCode = "";
      self.Location = null;
      self.Active = null;
      self.EditStatus = false;
      self.BeaconDialog = false;
    },

    inActiveBeaconByBeaconID(id) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        beaconID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${self.url}Beacon/inActiveBeaconByBeaconID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                self.getBeaconList();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              self.LoadingDialog = false;
            })
            .catch(function (error) {
              self.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          self.LoadingDialog = false;
        }
        self.LoadingDialog = false;
      });
    },

    ManageBeacon(Module) {
      let self = this;
      if (Module == "Create") {
        let temp = {
          BeaconName: self.BeaconName,
          MinorCode: self.MinorCode,
          MajorCode: self.MajorCode,
          Location: self.Location,
          Active: self.Active,
        };
        axios
          .post(`${self.url}Beacon/addBeacon`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
              self.CloseBeaconDialog();
              self.page = 1;
              self.getBeaconList();
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          beaconID: self.BeaconID,
          beaconName: self.BeaconName,
          MinorCode: self.MinorCode,
          MajorCode: self.MajorCode,
          Location: self.Location,
          Active: self.Active,
        };
        axios
          .post(`${self.url}Beacon/editBeacon`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
              self.CloseBeaconDialog();
              self.getBeaconList();
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
