<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Tenants
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="2" class="mt-4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        hide-details="auto"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" class="mt-4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar"
                        outlined
                        hide-details="auto"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="mt-4">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="searchTennant"
                    clearable
                    @keydown.enter.prevent="getTenant()"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  lg="4"
                  class="mt-3"
                  style="text-align: end"
                >
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="getTenant()"
                  >
                    Search

                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="green"
                    class="ma-2 white--text"
                    @click="checkDataButton('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="12" sm="6" md="2" lg="2">
                  <v-select
                    :items="['Show', 'Not Show']"
                    label="ShowKiosk"
                    outlined
                    dense
                    v-model="showKioskSearch"
                    required
                    clearable
                  ></v-select>
                </v-col> -->
                <v-col cols="12" sm="6" md="2" lg="2">
                  <v-select
                    :items="['Show', 'Not Show']"
                    label="ShowKiosk"
                    outlined
                    dense
                    v-model="showKioskSearch"
                    required
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2">
                  <v-select
                    :items="['Show', 'Not Show']"
                    label="ShowReception"
                    dense
                    outlined
                    v-model="showReceptionSearch"
                    required
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    dense
                    outlined
                    v-model="activeSearch"
                    required
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-select
                    label="Tower"
                    :items="TowerListDropdown"
                    item-text="towerName"
                    item-value="towerID"
                    v-model="towerID"
                    dense
                    outlined
                    required
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="TenantList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.tenantName }}</td>
                    <td class="header-table-css">{{ item.phone }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.canPreRegister == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.showKiosk == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.showReception == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="padding-bottom: 5px" align="center">
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 120px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="checkDataButton('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="deleteTenantByTenantID(item.tenantID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="warning"
                        @click="OpenDialogSyncDefaultFloor(item.tenantID)"
                      >
                        <v-icon dark>mdi-sync </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      max-width="1200px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white; padding: 5px;">
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span v-if="editCheck" class="text-h5">Edit Tenant</span>
            <span v-else class="text-h5">Create Tenant</span>
          </v-col>
          <v-col cols="6" sm="6" md="6" style="text-align: end">
            <v-tooltip bottom color="white" v-if="editCheck">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <v-row>
                <v-col cols="12" sm="12" md="12" style="font-size: 15px; color: black">
                  Create By : {{ createBy }} &nbsp; {{ ChangeFormatDate(createTime) }}<br />
                  Update By : {{ updateBy }} &nbsp; {{ ChangeFormatDate(updateTime) }}<br />
                </v-col>
              </v-row>
            </v-tooltip>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="1" sm="1" md="1" v-if="editCheck">
                    <v-text-field
                      label="TenantID*"
                      v-model="tenantID"
                      disabled
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      label="Tenant Name*"
                      v-model="tenantName"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      label="Tenant Name Eng*"
                      v-model="tenantNameEng"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      label="Tenant Name Thai*"
                      v-model="tenantNameThai"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      label="Phone*"
                      type="number"
                      maxlength="10"
                      v-model="phone"
                      required
                    ></v-text-field>
                    <!-- v-model="phone" -->
                  </v-col>
                  <v-col cols="3" sm="3" md="3">
                    <v-text-field
                      label="Email*"
                      v-model="email"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      label="Address*"
                      v-model="address"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-switch
                      v-model="showKiosk"
                      hide-details
                      inset
                      :label="`Kiosk`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-switch
                      v-model="showReception"
                      hide-details
                      inset
                      :label="`Reception`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      label="Remark"
                      v-model="remark"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-switch
                      v-model="preregister"
                      hide-details
                      inset
                      :label="'Preregister'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-switch
                      v-model="active"
                      hide-details
                      inset
                      :label="active ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="tagTowerName.length !== 0">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    align="left"
                    style="color: #000000; font-size: 18px"
                  >
                    <v-icon> mdi-tag </v-icon> {{ tag(tagTowerName) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <template>
                  <v-card class="mx-auto">
                    <v-card-title class="white--text primary darken-4">
                      Tower Name :
                      <v-spacer></v-spacer>
                      <v-autocomplete
                        v-model="towerNameList"
                        :items="TowerListArray"
                        :search-input.sync="search"
                        clearable
                        hide-details
                        hide-selected
                        item-text="towerName"
                        item-value="towerID"
                        solo
                        @change="getDataZoneAndFloorByTowerID()"
                      >
                      </v-autocomplete>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="border: outset; width: unset; border-width: thick"
                    >
                      <table v-if="zoneList.length > 0" style="width: 100%; border-spacing: 0px;">
                        <thead>
                          <tr>
                            <th class="text-center" style="color: #000000; background-color: orange; width: 50px; border-right: 1px solid;">
                              ชั้น/โซน
                            </th>
                            <th
                              class="text-center"
                              style="padding: 5px; color: #000000; background-color: skyblue; border-right: 1px solid;"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in floorList" :key="item.id">
                            <td style="color: #000000; text-align: center; background-color: lightgray; border-right: 1px solid; height: 50px; width: 30px;">
                              {{ item.name }}
                            </td>
                            <td v-for="items in zoneList" :key="items.id" style="border-right: 1px solid; width: 100px;">
                              <div
                                style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <v-checkbox
                                  :style="[
                                    ZoneWithFloor(item.id, items.id) == true
                                      ? { display: 'none' }
                                      : { display: 'grid' },
                                    { 'justify-content': 'space-around' },
                                  ]"
                                  v-model="checkbox"
                                  @click="
                                    checkboxClick(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  :value="CheckBoxData(item.id, items.id)"
                                ></v-checkbox>
                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    !checkButton(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      true
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="cursor: pointer; color: gray; display: grid; justify-content: space-around;"
                                  >Default</v-btn
                                >

                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    checkButton(CheckBoxData(item.id, items.id))
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      false
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="cursor: pointer; color: green"
                                  >Default</v-btn
                                >
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th class="text-center" style="color: #000000; background-color: orange; width: 50px; border-right: 1px solid;">
                              ชั้น/โซน
                            </th>
                            <th class="text-center" style="padding: 5px; color: #000000; background-color: skyblue; border-right: 1px solid;"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>

                    <!-- <v-virtual-scroll
                      :items="getRoleAuthFloors"
                      :item-height="50"
                      height="500"
                      v-if="dialog"
                    >
                      <template v-slot:default="{ item, count }">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.locationID
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.zoneName
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.floorName
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              :color="item.choose ? 'error' : 'success'"
                              fab
                              x-small
                              v-model="ChooseLocationBTN[count]"
                              dark
                              @click="activeCheck(item)"
                            >
                              <v-icon v-if="item.choose == true"
                                >mdi-minus</v-icon
                              >
                              <v-icon v-else>mdi-plus</v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              v-model="ChooseDefaultFloorBTN[count]"
                              small
                              :disabled="!item.choose"
                              @click="activeCheckDefaultFloor(item)"
                            >
                              Default Floor

                              <v-icon
                                v-if="item.defaultFloor"
                                color="green"
                                right
                              >
                                mdi-check-circle-outline
                              </v-icon>
                              <v-icon
                                v-if="!item.defaultFloor"
                                color="black"
                                right
                              >
                                mdi-minus-circle-outline
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll> -->
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                large
                :loading="loading4"
                :disabled="!valid"
                width="100"
                color="success"
                @click="addTennant()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                large
                width="100"
                append-icon="mdi-close-circle-outline"
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogSyncDefaultFloor" persistent width="400px">
      <v-card>
        <v-card-title>
          <span style="font-size: 24px">Sync Default Floor</span>
          <v-spacer></v-spacer>
          <v-btn
            style="margin-top: -10px; z-index: 1"
            icon
            @click="CloseDialogSyncDefaultFloor()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <span style="font-size: 18px"
                  >Do you want to sync default floor to all member in
                  tenant?</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  placeholder="Password"
                  :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="ShowPassword ? 'text' : 'password'"
                  @click:append="ShowPassword = !ShowPassword"
                  id="password"
                  v-model="PasswordSyncDefaultFloor"
                />
                <p style="color: red; text-align: center">
                  *กรอกรหัสยืนยันตัวตน
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" align="center">
                <v-btn
                  variant="outlined"
                  color="success"
                  large
                  width="100"
                  @click="SyncDefaultFloorMemberTenant()"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "ManageMember",
  components: {
    AlertComponent,
  },
  data() {
    return {
      valid: true,
      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20, 50, 100],
      itemsDropdown: ["Male", "Female"],
      valueDropdown: [],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      searchTennant: "",
      showKioskSearch: null,
      showReceptionSearch: null,
      showStatusSearch: null,
      AlertDialog: false,
      preregister: false,
      TypeAlert: null,
      MessageAlert: "",
      addData: false,

      tenantAuthFloors: [],
      getRoleAuthFloors: [],
      autoFloors: [],
      selectAuthFloors: [],

      tenantID: "",
      tenantName: "",
      tenantNameEng: "",
      tenantNameThai: "",
      phone: "",
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      address: "",
      showKiosk: true,
      showReception: true,
      remark: "",
      active: true,
      locationList: [],
      TenantList: [],
      TowerListArray: [],
      TowerListDropdown: [],
      getTowerArray: [],
      getTowerZone: [],

      locationMappingMain: [],
      locationMapping: [],
      locationFloorMap: [],
      floorList: [],
      zoneList: [],
      towerList: [],
      defaultArray: [],
      checkbox: [],
      checkboxFloor: [],

      perpage: 10,

      startDate: "",
      endDate: "",
      towerNameList: "",
      tagTowerName: [],
      Status: true,
      activeSearch: null,
      menu: false,
      menu1: false,
      modal: false,
      menu2: false,
      editCheck: false,
      ChooseLocationBTN: false,
      ChooseDefaultFloorBTN: false,
      permissionsPage: [],
      DialogSyncDefaultFloor: false,
      PasswordSyncDefaultFloor: "",
      ShowPassword: false,
      towerID: null,

      createBy: "",
      createTime: "",
      updateBy: "",
      updateTime: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "TenantName",
          align: "left",
          sortable: false,
        },
        {
          text: "Phone",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateDate",
          align: "left",
          sortable: false,
        },
        {
          text: "PreRegister",
          align: "center",
          sortable: false,
        },
        {
          text: "ShowKiosk",
          align: "center",
          sortable: false,
        },
        {
          text: "ShowReception",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          width: 230,
        },
      ];
    },
  },

  watch: {
    checkbox() {
      let self = this;
      if (self.defaultArray.find((a) => a.towerID == self.towerName) != null) {
        self.defaultArray.find((a) => a.towerID == self.towerName).selectFloor =
          self.checkbox;
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  mounted() {
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    this.getTowerList();
    this.getTenant();
    this.GetRoleAuthFloors();
    this.getTowerDropdownList();
  },

  methods: {
    OpenDialogSyncDefaultFloor(tenantID) {
      let self = this;
      self.tenantID = tenantID;
      self.DialogSyncDefaultFloor = true;
    },

    CloseDialogSyncDefaultFloor() {
      let self = this;
      self.PasswordSyncDefaultFloor = "";
      self.DialogSyncDefaultFloor = false;
    },

    SyncDefaultFloorMemberTenant() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        tenantID: self.tenantID,
        password: self.PasswordSyncDefaultFloor,
      };

      axios
        .post(`${self.url}Tenant/SyncDefaultFloorMemberTenant`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            self.DialogSyncDefaultFloor = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 2) {
            self.DialogSyncDefaultFloor = false;
            self.LoadingDialog = false;
            eventBus.$emit("logout");
          }
          // self.LoadingDialog = false;
          // self.DialogSyncDefaultFloor = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          // self.DialogSyncDefaultFloor = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    checkValidate() {
      if (
        this.tenantName == "" ||
        this.tenantNameEng == "" ||
        this.tenantNameThai == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.address == ""
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return true;
      } else {
        return false;
      }
    },

    CheckDefaultFloor() {
      let data = [];
      data = this.defaultArray.filter((x) => x.defaultFloor.length > 0);
      if (data.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "Please Set Default Floor",
          showConfirmButton: false,
          timer: 1500,
        });
        return true;
      } else {
        return false;
      }
    },

    addTennant() {
      let that = this;
      if (that.checkValidate()) {
        return;
      }
      if (that.CheckDefaultFloor()) {
        return;
      }
      that.loading4 = true;
      // that.dialog = false;
      if (that.tenantName == "") {
        setTimeout(function () {}, 2000);
        return;
      }

      // let tempDataList = this.getRoleAuthFloors.filter((x) => x.choose == true);

      // for (let i = 0; i < tempDataList.length; i++) {
      //   let list = {
      //     locationID: tempDataList[i].locationID,
      //     isDefaultFloor: tempDataList[i].defaultFloor,
      //   };
      //   that.locationList.push(list);
      // }

      that.LoadingDialog = true;

      if (!that.editCheck) {
        let temp = {
          tenantName: that.tenantName,
          tenantNameEng: that.tenantNameEng,
          tenantNameThai: that.tenantNameThai,
          phone: that.phone,
          email: that.email,
          address: that.address,
          showKiosk: that.showKiosk,
          showReception: that.showReception,
          remark: that.remark,
          active: that.active,
          preregister: that.preregister,
          defaultArray: that.defaultArray,
        };
        axios
          .post(`${that.url}Tenant/addTenant`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getTenant();
            that.loading4 = false;
            that.dialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          tenantID: that.tenantID,
          tenantName: that.tenantName,
          tenantNameEng: that.tenantNameEng,
          tenantNameThai: that.tenantNameThai,
          phone: that.phone,
          email: that.email,
          address: that.address,
          showKiosk: that.showKiosk,
          showReception: that.showReception,
          remark: that.remark,
          preregister: that.preregister,
          active: that.active,
          defaultArray: that.defaultArray,
        };
        // return;
        axios
          .post(`${that.url}Tenant/updateTenant`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getTenant();
            that.dialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    activeCheck(data) {
      let that = this;
      data.choose = !data.choose;
      that.ChooseLocationBTN = !that.ChooseLocationBTN;
    },
    activeCheckDefaultFloor(data) {
      let that = this;

      if (
        that.getRoleAuthFloors.filter((x) => x.defaultFloor == true).length > 0
      ) {
        for (
          let i = 0;
          i <
          that.getRoleAuthFloors.filter((x) => x.defaultFloor == true).length;
          i++
        ) {
          that.getRoleAuthFloors.filter((x) => x.defaultFloor == true)[
            i
          ].defaultFloor = false;
        }
      }

      data.defaultFloor = !data.defaultFloor;
      that.ChooseDefaultFloorBTN = !that.ChooseDefaultFloorBTN;
    },
    getTenant() {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: that.searchTennant == null ? "" : that.searchTennant,
        showKiosk:
          that.showKioskSearch == "Show"
            ? true
            : that.showKioskSearch == null
            ? null
            : false,
        showReception:
          that.showReceptionSearch == "Show"
            ? true
            : that.showReceptionSearch == null
            ? null
            : false,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
        towerID: that.towerID,
      };
      axios
        .post(`${that.url}Tenant/getDataTenantList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TenantList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    checkDataButton(type, data) {
      let that = this;
      that.loading4 = false;
      // that.GetRoleAuthFloors();
      if (type == "add") {
        (that.tenantName = ""),
          (that.tenantNameEng = ""),
          (that.tenantNameThai = ""),
          (that.phone = ""),
          (that.email = ""),
          (that.address = ""),
          (that.showKiosk = true),
          (that.showReception = true),
          (that.remark = ""),
          (that.active = true),
          (that.editCheck = false);

        that.towerNameList = "";
        that.tagTowerName = [];
        that.floorList = [];
        that.zoneList = [];
        that.defaultArray = [];
        that.checkbox = "";
        that.checkboxFloor = "";

        this.getTowerList();
        that.dialog = true;
      } else {
        that.getTenantByTenantID(data.tenantID);
      }
    },

    getTenantByTenantID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        tenantID: id,
      };
      axios
        .post(`${that.url}Tenant/getDataTenantByTenantID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.mapEdit(response.data.data[0]);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getDataZoneAndFloorByTowerID() {
      let that = this;
      that.LoadingDialog = true;
      if (that.towerNameList == null) {
        that.getTowerZone = [];
        return;
      }
      let temp = {
        towerID: that.towerNameList,
      };
      axios
        .post(`${that.url}Tenant/getDataZoneAndFloorByTowerID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.checkbox = [];
            that.checkboxFloor = [];
            that.getTowerZone = response.data.data;
            that.locationMapping =
              that.getTowerZone.accessMapping.locationMapping;
            that.locationFloorMap =
              that.getTowerZone.accessMapping.locationFloorMap;

            that.towerList = that.getTowerZone.towerList;
            that.towerName = that.getTowerZone.towerList.towerID;

            that.floorList = that.getTowerZone.towerList.floorList;
            that.zoneList = that.getTowerZone.towerList.zoneList;
            that.checkbox = that.defaultArray.find(
              (a) => a.towerID == that.towerName
            ).selectFloor;

            that.checkboxFloor = that.defaultArray.find(
              (a) => a.towerID == that.towerName
            ).defaultFloor;
            that.$forceUpdate();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
          that.dialog = true;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    setDefaultFloor(value, checked) {
      let that = this;
      if (checked) {
        this.OneFloorinZone(value);
        if (that.checkbox.indexOf(value) != -1) {
          that.checkboxFloor.push(value);
        }
      } else {
        let index = that.checkboxFloor.indexOf(value);
        that.checkboxFloor.splice(index, 1);
      }
    },

    OneFloorinZone(value) {
      let that = this;
      let mainZoneID = this.locationMapping.find(
        (x) => x.locationID == value && x.towerID == this.towerName
      ).zoneID;

      let defaultData = this.defaultArray.find(
        (f) => f.towerID == this.towerName
      ).defaultFloor;

      defaultData.forEach((item) => {
        let findZoneDuplicate = this.locationMapping.find(
          (x) => x.locationID == item && x.towerID == this.towerName
        ).zoneID;
        if (mainZoneID == findZoneDuplicate) {
          if (that.checkbox.find((a) => a == value) != null) {
            let index = that.checkboxFloor.indexOf(item);
            that.checkboxFloor.splice(index, 1);
          }
        }
      });
    },

    checkboxClick(value) {
      let that = this;

      that.checkboxFloor.forEach((x) => {
        if (that.checkbox.find((a) => a == x) == null) {
          let index = that.checkboxFloor.indexOf(value);
          that.checkboxFloor.splice(index, 1);
        }
      });
    },
    checkButton(value) {
      let that = this;
      let defaultFloor = that.checkboxFloor.find((x) => x == value);
      let selectFloor = that.checkbox.find((x) => x == value);
      if (defaultFloor != null && selectFloor != null) {
        return true;
      }

      return false;
    },

    CheckBoxData(floorID, zoneID) {
      let temp = "";
      let dataCheck = this.locationFloorMap.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        temp = dataCheck.locationID;
        return temp;
      }
      return temp;
    },

    ZoneWithFloor(floorID, zoneID) {
      let dataCheck = this.locationFloorMap.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        return false;
      }

      return true;
    },

    getTowerList() {
      let that = this;
      axios
        .get(`${that.url}Tenant/getTowerList`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerListArray = response.data.data;
            that.defaultArray = [];
            that.TowerListArray.forEach((element) => {
              let tempData = {
                towerID: element.towerID,
                selectFloor: [],
                defaultFloor: [],
              };
              that.defaultArray.push(tempData);
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getTowerDropdownList() {
      let self = this;
      axios
        .get(`${self.url}Tower/getTower`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TowerListDropdown = response.data.data;      
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    deleteTenantByTenantID(id) {
      let that = this;
      let temp = {
        tenantID: id,
      };
      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Tenant ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Tenant/InactiveTenantByTenantID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getTenant();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    mapEdit(data) {
      let that = this;

      that.editCheck = true;
      that.towerNameList = "";
      that.tagTowerName = [];
      that.floorList = [];
      that.zoneList = [];
      that.tenantName = data.tenantName;
      that.tenantNameEng = data.nameEng;
      that.tenantNameThai = data.nameThai;
      that.phone = data.phone;
      that.email = data.email;
      that.address = data.address;
      that.showKiosk = data.showKiosk;
      that.showReception = data.showReception;
      that.remark = data.remark;
      that.active = data.active;
      that.preregister = data.preregister;
      that.createBy = data.createBy;
      that.createTime = data.createTime;
      that.updateBy = data.updateBy;
      that.updateTime = data.updateTime;
      that.tenantID = data.tenantID;

      for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
        that.getRoleAuthFloors[i].choose = false;
        that.getRoleAuthFloors[i].defaultFloor = false;
      }

      that.defaultArray = [];

      that.TowerListArray.forEach((element) => {
        let tempData = {
          towerID: element.towerID,
          selectFloor: [],
          defaultFloor: [],
        };
        that.defaultArray.push(tempData);
      });

      data.defaultArray.forEach((value) => {
        that.tagTowerName.push(value.towerID);
        let item = that.defaultArray.find((x) => x.towerID == value.towerID);
        if (item != null) {
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).defaultFloor = value.defaultFloor;
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).selectFloor = value.selectFloor;
        }
      });
      that.towerNameList =
        data.defaultArray.length != 0 ? data.defaultArray[0].towerID : "";
      if (that.towerNameList != "") {
        that.getDataZoneAndFloorByTowerID();
      } else {
        that.LoadingDialog = false;
        that.dialog = true;
      }

      // that.LoadingDialog = true;
      // that.dialog = true;
    },

    tag(data) {
      let that = this;
      let tagName = "";
      // console.log(this.TenantList);
      data.forEach((value) => {
        let towername =
          that.TowerListArray == null
            ? ""
            : that.TowerListArray.find((a) => a.towerID == value).towerName;
        if (tagName == "") {
          tagName = towername;
        } else {
          tagName += " ," + towername;
        }
      });
      return tagName;
    },

    ChangePage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = value;
      let temp = {
        page: value,
        perpage: that.itemsPerPage,
        search: that.searchTennant == null ? "" : that.searchTennant,
        showKiosk:
          that.showKioskSearch == "Show"
            ? true
            : that.showKioskSearch == null
            ? null
            : false,
        showReception:
          that.showReceptionSearch == "Show"
            ? true
            : that.showReceptionSearch == null
            ? null
            : false,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
        towerID: that.towerID,
      };
      axios
        .post(`${that.url}Tenant/getDataTenantList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TenantList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    GetRoleAuthFloors() {
      let that = this;
      that.LoadingDialog = true;

      axios
        .get(`${that.url}Tenant/GetRoleAuthFloors`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getRoleAuthFloors = response.data.data;
            for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
              that.getRoleAuthFloors[i].choose = false;
              that.getRoleAuthFloors[i].defaultFloor = false;
            }
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: that.searchTennant == null ? "" : that.searchTennant,
        showKiosk:
          that.showKioskSearch == "Show"
            ? true
            : that.showKioskSearch == null
            ? null
            : false,
        showReception:
          that.showReceptionSearch == "Show"
            ? true
            : that.showReceptionSearch == null
            ? null
            : false,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
        towerID: that.towerID,
      };
      axios
        .post(`${that.url}Tenant/getDataTenantList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TenantList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
