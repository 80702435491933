<template>
  <div>
    <!-- Main section -->
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Members Residence
        </div>
        <v-col
          cols="12"
          md="12"
          style="display: flex; justify-content: flex-end"
        >
        </v-col>
        <v-card-text>
          <div>
            <v-card elevation="10" style="padding: 2%; margin-bottom: 0%">
              <v-row style="margin-bottom: -3%">
                <v-col cols="12" md="2" lg="2">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="searchStartDate"
                        label="Start Date"
                        append-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchStartDate"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" lg="2">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="searchEndDate"
                        label="Start Date"
                        append-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchEndDate"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" lg="2">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="searchActive"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    dense
                    v-model="searchText"
                    outlined
                    placeholder="Search"
                    v-on:keyup.enter="getDataResidenceMember()"
                    placeholder-color="#ACACAC"
                    prepend-inner-icon="mdi-magnify"
                    color="#126496"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" lg="12" style="text-align: right">
                  <v-btn
                    color="blue"
                    class="ma-2 white--text"
                    @click="getDataResidenceMember()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="pink"
                    class="ma-2 white--text"
                    @click="openEditMemberDialog('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 1%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="memberResidenceList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">
                      {{ item.firstname + " " + item.lastname }}
                    </td>
                    <td class="header-table-css">
                      <v-icon size="30" color="black">{{
                        item.gender == "M"
                          ? "mdi-face"
                          : item.gender == "F"
                          ? "mdi-face-woman"
                          : ""
                      }}</v-icon>
                    </td>
                    <td class="header-table-css">
                      <v-icon size="30" color="black">{{
                        item.hasCard == 1 ? "mdi-card-bulleted-outline" : ""
                      }}</v-icon>
                    </td>
                    <td class="header-table-css">
                      <v-tooltip
                        v-model="tooltips[index]"
                        top
                        open-on-click
                        color="black"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ attrs }">
                          <v-icon
                            v-bind="attrs"
                            @click="toggleTooltip(index)"
                            color="black"
                            style="cursor: pointer"
                          >
                            {{
                              item.residenceIDList.length > 0
                                ? "mdi-home-account"
                                : ""
                            }}
                          </v-icon>
                        </template>
                        <span
                          ><v-list style="color: black; background: black">
                            <v-list-item
                              v-for="(res, idx) in item.residenceIDList"
                              :key="idx"
                            >
                              <v-list-item-title style="color: white">{{
                                res.unitNumber
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list></span
                        >
                      </v-tooltip>
                    </td>
                    <td class="header-table-css">
                      {{ changeFormatDate(item.createTime) }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="getColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: getColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="openEditMemberDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="
                          inActiveResidenceMemberByPersonID(item.personID)
                        "
                        :disabled="!item.active"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="changePagination('ChangePage', page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="changePagination('ChangePerPage', itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <!-- Add/Edit Member -->
    <v-dialog v-model="dialogEditMember" persistent max-width="1000px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="!statusEditMember" class="text-h5"
            >Create Member Residence</span
          >
          <span v-if="statusEditMember" class="text-h5"
            >Edit Member Residence</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-row>
                  <v-col cols="12" sm="6" md="2" v-if="statusEditMember">
                    <v-text-field
                      label="Member Id*"
                      v-model="memberID"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      label="First Name*"
                      v-model="firstname_edit"
                      required
                      :readonly="!activeStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      label="Last Name*"
                      v-model="lastname_edit"
                      required
                      :readonly="!activeStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-select
                      :items="['ไม่ระบุ', 'Male', 'Female']"
                      label="Gender"
                      v-model="gender_edit"
                      required
                      :readonly="!activeStatus"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      label="Country code"
                      v-model="countryCode_edit"
                      readonly
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Phone Number"
                      hint="*หมายเหตุ ใส่เป็นตัวเลขหรือค่าว่างเท่านั้น"
                      v-model="phone_edit"
                      readonly
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="dialogEditPhone = true"
                      :disabled="!activeStatus"
                    >
                      Add
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="ID Card (รหัสบัตรประชาชน)"
                      append-icon="mdi-card-account-details-outline"
                      v-model="idcard_edit"
                      placeholder="1209712345678"
                      type="number"
                      required
                      :readonly="!activeStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Passport"
                      append-icon="mdi-passport"
                      placeholder="A1234567"
                      v-model="passport_edit"
                      required
                      :readonly="!activeStatus"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5" md="5">
                    <v-text-field
                      label="Email"
                      append-icon="mdi-email-outline"
                      v-model="email_edit"
                      readonly
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" style="text-align: left">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="dialogEditEmail = true"
                      :disabled="!activeStatus"
                    >
                      Add
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-autocomplete
                      :items="residenceDropDownList"
                      item-value="residenceID"
                      item-text="unitNumber"
                      label="Residence"
                      multiple
                      v-model="residenceIDList_edit"
                      clearable
                      :readonly="!activeStatus"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      label="Note"
                      append-icon="mdi-note-text-outline"
                      v-model="note_edit"
                      :readonly="!activeStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-switch
                      v-model="activeStatus_edit"
                      hide-details
                      inset
                      :label="activeStatus_edit ? 'Active' : 'InActive'"
                      :disabled="!statusEditMember || !activeStatus"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" md="3" style="text-align: center">
                <v-img
                  v-if="urlImg == null"
                  class="bg-white"
                  width="300"
                  style="
                    border: 1px solid #000000;
                    cursor: pointer;
                    background-size: contain;
                  "
                  :aspect-ratio="1"
                  :src="logoMember"
                  cover
                ></v-img>
                <v-img
                  v-if="urlImg != null"
                  class="bg-white"
                  width="300"
                  style="border: 1px solid #000000; cursor: pointer"
                  :aspect-ratio="1"
                  :src="urlImg"
                  @click="dialogChooseImage = true"
                  :disabled="!activeStatus"
                  cover
                ></v-img>
                <v-file-input
                  id="image"
                  label="Input image"
                  accept="image/png, image/jpeg, image/bmp"
                  append-icon="mdi-camera"
                  v-model="image"
                  :disabled="!activeStatus"
                ></v-file-input>

                <v-row style="text-align: center; margin-left: 25px">
                  <v-col cols="12" sm="3" md="3">
                    <v-btn
                      class="ma-2"
                      style="min-width: 100px; color: white"
                      color="#000000"
                      @click="openDialog('CardResidence', true)"
                      :disabled="!activeStatus"
                    >
                      Card Residence
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="!statusEditMember"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addResidenceMember('Add')"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-if="statusEditMember"
                class="ma-2"
                style="min-width: 100px"
                :disabled="!activeStatus"
                color="success"
                @click="addResidenceMember('Update')"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="closeEditMemberDialog()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add/Edit Phone number -->
    <v-dialog v-model="dialogEditPhone" persistent max-width="600px">
      <v-card>
        <v-card-title> Phone </v-card-title>
        <v-card-text>
          <v-btn color="primary" dark @click="openDialog('AddPhone', true)">
            Add Phone
          </v-btn>
          <v-data-table
            :headers="headersPhone"
            :items="phoneList_edit"
            :items-per-page="itemsPerPage"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-arrow-down</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultPhone(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-off</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="deleteItem('Phone', item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="gray" @click="dialogEditPhone = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddPhone" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add Phone</span>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-autocomplete
                :items="countryCodeJson"
                item-value="code"
                item-text="country"
                label="Country"
                v-model="countryCode_add"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="7" md="7">
              <v-text-field
                v-model="phone_add"
                label="Phone"
                hide-details
                single-line
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="addPhone()"> Save</v-btn>
          <v-btn color="gray" @click="dialogAddPhone = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add/Edit E-mail -->
    <v-dialog v-model="dialogEditEmail" persistent max-width="600px">
      <v-card>
        <v-card-title> Email </v-card-title>
        <v-card-text>
          <v-btn color="primary" dark @click="openDialog('AddEmail', true)">
            Add Email
          </v-btn>
          <v-data-table
            :headers="headersEmail"
            :items="emailList_edit"
            :items-per-page="itemsPerPage"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-email-check-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultEmail(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-email-remove-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="deleteItem('Email', item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="gray" @click="dialogEditEmail = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddEmail" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add Email</span>
          <v-spacer></v-spacer>
          <v-row>
            <v-text-field
              v-model="email_add"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="addEmail()"> Save</v-btn>
          <v-btn color="gray" @click="dialogAddEmail = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Choose Image -->
    <v-dialog v-model="dialogChooseImage" persistent width="800px">
      <v-card>
        <v-card-title> Image </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersImage"
            :items="imageList_edit"
            :items-per-page="itemsPerPage"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-arrow-down</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultImage(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-off</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.base64="{ item }">
              <v-img
                class="bg-white"
                width="100"
                style="border: 1px solid #000000; cursor: pointer"
                :aspect-ratio="1"
                :src="item.base64"
                cover
              ></v-img>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="deleteItem('Image', item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="gray" @click="dialogChooseImage = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add/Edit Card Residence -->
    <v-dialog v-model="dialogCardResidence" persistent max-width="1000px">
      <v-card>
        <v-card-title style="background-color: #96d5e8; color: black">
          <span class="text-h5">Card Residence</span>
        </v-card-title>
        <v-card-text>
          <br />
          <v-row>
            <v-col cols="12" md="6">
              <label>Card Number</label>
              <v-autocomplete
                :items="cardResidenceDropdownList"
                item-value="cardID"
                item-text="cardNumber"
                :disabled="false"
                color="#126496"
                prepend-inner-icon="mdi-magnify"
                cols="12"
                sm="6"
                md="3"
                hide-no-data
                return-object
                outlined
                dense
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-row style="height: 50px; margin-top: -0.5rem">
                        <v-col>
                          <v-list-item-title
                            v-html="data.item.cardNumber"
                          ></v-list-item-title>
                        </v-col>
                        <v-col class="text-end">
                          <v-btn
                            text
                            style="
                              background-color: #126496;
                              color: #ffffff;
                              text-transform: none !important;
                              margin-top: -0.4rem;
                            "
                            @click="addCardResidence(data.item)"
                            >Add</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersCardResidence"
            :items="cardResidenceList_edit"
            :items-per-page="itemsPerPageCardResidence"
            :page.sync="pageCardResidence"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="header-table-css">
                  {{
                    (pageCardResidence - 1) * itemsPerPageCardResidence +
                    (index + 1)
                  }}
                </td>
                <td class="header-table-css">{{ item.cardNumber }}</td>
                <td class="header-table-css">
                  {{ changeFormatDateNoTime(item.startDate) }}
                </td>
                <td class="header-table-css">
                  {{ changeFormatDateNoTime(item.endDate) }}
                </td>
                <td
                  style="
                    padding-bottom: 5px;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    justify-content: center;
                  "
                >
                  <v-checkbox
                    v-model="item.active"
                    color="success"
                  ></v-checkbox>
                </td>
                <td class="header-table-css">
                  <v-btn text @click="deleteItem('CardResidence', item)">
                    <img src="@/assets/close.png" width="15" height="15" />
                    <span style="color: #ff4839" class="ml-1 text-capitalize"
                      >Delete</span
                    >
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="openDialog('CardResidence', false)"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading -->
    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";
import countryCodeJson from "../../plugins/countryCode.json";

export default {
  name: "MemberResidence",
  data() {
    return {
      countryCodeJson: countryCodeJson,
      url: enurl.apiUrl,
      show: false,
      tooltips: [],

      // Search
      searchStartDate: "",
      searchEndDate: "",
      searchActive: null,
      searchText: "",
      menuStartDate: false,
      menuEndDate: false,

      residenceDropDownList: [],
      memberResidenceList: [],

      // Dialog : Edit member
      dialogEditMember: false,
      statusEditMember: false,
      memberID: "",
      personID_edit: "",
      firstname_edit: "",
      lastname_edit: "",
      gender_edit: "",
      idcard_edit: "",
      passport_edit: "",
      note_edit: "",
      activeStatus: true,
      activeStatus_edit: true,
      residenceIDList_edit: [],
      cardResidenceList_edit: [],
      email_add: "",
      email_edit: "",
      emailList_edit: [],
      countryCode_edit: "",
      countryCode_add: "",
      phone_edit: "",
      phone_add: "",
      phoneList_edit: [],
      image: null,
      base64: null,
      urlImg: null,
      imageList_edit: [],
      logoMember: require("@/assets/picture-default.jpg"),

      cardNumber: "",
      cardResidenceDropdownList: [],

      dialogEditPhone: false,
      dialogAddPhone: false,
      dialogEditEmail: false,
      dialogAddEmail: false,
      dialogChooseImage: false,
      dialogCardResidence: false,
      loadingDialog: false,

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail is invalid",
      ],

      // Page
      permissionsPage: [],
      no_run: 0,
      no_runCardResidence: 0,
      page: 1,
      pageCardResidence: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageCardResidence: 5,
      items: [10, 15, 20, 50, 100],

      headersCardResidence: [
        {
          text: "No",
          sortable: false,
          align: "center",
          value: "no",
        },
        {
          text: "CardNumber",
          align: "center",
          sortable: false,
          value: "cardNumber",
        },
        {
          text: "StartDate",
          align: "center",
          sortable: false,
          value: "startDate",
        },
        {
          text: "expiredDate",
          align: "center",
          sortable: false,
          value: "expiredDate",
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersEmail: [
        {
          text: "Default Email",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Email Name",
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      headersPhone: [
        {
          text: "Default Phone",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Country Name",
          align: "left",
          value: "country",
          sortable: false,
        },
        {
          text: "Country Code",
          align: "left",
          value: "code",
          sortable: false,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      headersImage: [
        {
          text: "Default Image",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Name Image",
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: "Image",
          align: "center",
          value: "base64",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
        },
        {
          text: "Gender",
          align: "center",
          sortable: false,
        },
        {
          text: "Card",
          align: "center",
          sortable: false,
        },
        {
          text: "Residence",
          align: "center",
          sortable: false,
        },
        {
          text: "CreateDate",
          align: "center",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
    image(newVal) {
      if (newVal) {
        this.createBase64Image();
      } else {
        this.base64 = "";
        this.urlImg = null;
      }
    },
  },
  mounted() {
    let permission = localStorage.getItem("Permision");
    if (permission == null || permission == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(permission).find(
      (x) => x.appName == page
    );

    this.getDataResidenceMember();
    this.getResidenceDropdownList();
  },
  methods: {
    // Call API
    getDataResidenceMember() {
      let that = this;
      that.loadingDialog = true;
      let requestData = {
        page: that.page,
        perPage: that.itemsPerPage,
        search: that.searchText == null ? "" : that.searchText,
        startDate: that.searchStartDate,
        endDate: that.searchEndDate,
        active:
          that.searchActive == "Active"
            ? true
            : that.searchActive == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Residence/getDataResidenceMember`, requestData)
        .then(function (response) {
          if (response.data.status == 0) {
            that.memberResidenceList = response.data.data.listData;
            [that.pageCount, that.no_run] = that.calculatePagination(
              response.data.data.total,
              that.page,
              that.itemsPerPage
            );
            that.tooltips = new Array(that.memberResidenceList.length).fill(
              false
            );
            that.loadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    inActiveResidenceMemberByPersonID(personID) {
      let that = this;
      that.loadingDialog = true;
      let requestData = {
        personID: personID,
      };

      Swal.fire({
        title: "Inactive",
        text: "Do you want to inactive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `${that.url}Residence/inActiveResidenceMemberByPersonID`,
              requestData
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.getDataResidenceMember();
                that.loadingDialog = false;
                Swal.fire("Deleted!", "Member has been inactive.", "success");
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
            })
            .catch(function (error) {
              that.loadingDialog = false;
              if (error.response.status != 404) {
                that.loadingDialog = false;
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
        }
        that.loadingDialog = false;
      });
    },
    changePagination(mode, value) {
      let that = this;
      that.loadingDialog = true;
      let requestData = {
        page: that.page,
        perPage: that.itemsPerPage,
        search: that.searchText == null ? "" : that.searchText,
        startDate: that.searchStartDate,
        endDate: that.searchEndDate,
        active:
          that.searchActive == "Active"
            ? true
            : that.searchActive == null
            ? null
            : false,
      };

      if (mode == "ChangePage") {
        that.page = value;
        requestData.page = value;
        requestData.perPage = that.itemsPerPage;
      } else {
        that.page = 1;
        requestData.page = 1;
        requestData.perPage = value;
      }
      axios
        .post(`${that.url}Residence/getDataResidenceMember`, requestData)
        .then(function (response) {
          if (response.data.status == 0) {
            that.memberResidenceList = response.data.data.listData;
            [that.pageCount, that.no_run] = that.calculatePagination(
              response.data.data.total,
              that.page,
              that.itemsPerPage
            );
            that.tooltips = new Array(that.memberResidenceList.length).fill(
              false
            );
            that.loadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getDetailResidenceMemberByPersonID(personID) {
      let that = this;
      that.loadingDialog = true;
      let requestData = {
        personID: personID,
      };
      axios
        .post(
          `${that.url}Residence/getDetailResidenceMemberByPersonID`,
          requestData
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.mapMemberResidenceData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async addResidenceMember(type) {
      let that = this;
      that.loadingDialog = true;
      let [statusValidate, error_msg] = that.validateAddResidenceData();
      if (!statusValidate) {
        that.loadingDialog = false;
        Swal.fire({
          icon: "warning",
          html: error_msg,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      let cardResidenceEditTime = that.cardResidenceList_edit;
      cardResidenceEditTime.forEach((element) => {
        element.startDate = that.convertDate(element.startDate);
        element.endDate = that.convertDate(element.endDate);
      });

      let requestData = {
        firstname: that.firstname_edit.trim(),
        lastname: that.lastname_edit.trim(),
        gender:
          that.gender_edit == "Male"
            ? "M"
            : self.gender_edit == "Female"
            ? "F"
            : "",
        idcard: that.idcard_edit.trim(),
        passport: that.passport_edit.trim(),
        note: that.note_edit,
        serviceType: [4],
        residentIDList: that.residenceIDList_edit,
        cardResidenceList: cardResidenceEditTime,
        emailList: that.emailList_edit,
        phoneList: that.phoneList_edit,
        imageList: that.imageList_edit,
        active: that.activeStatus_edit,
      };

      if (type == "Add") {
        // Add member
        requestData.active = true;
        await axios
          .post(`${that.url}Residence/addResidenceMember`, requestData)
          .then(function (response) {
            if (response.data.status == 0) {
              that.loadingDialog = false;
              that.dialogEditMember = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.page = 1;
            that.getDataResidenceMember();
            that.loadingDialog = false;
          })
          .catch(function (error) {
            that.loadingDialog = false;
            if (error.response.status != 404) {
              that.loadingDialog = false;
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else if (type == "Update" && requestData.active == false) {
        // Update member (but inactive)
        requestData.personID = that.personID_edit;
        await axios
          .post(`${that.url}Residence/updateResidenceMember`, requestData)
          .then(function (response) {
            if (response.data.status == 0) {
              let requestData2 = { personID: requestData.personID };
              axios
                .post(
                  `${that.url}Residence/inActiveResidenceMemberByPersonID`,
                  requestData2
                )
                .then(function (response2) {
                  if (response2.data.status == 0) {
                    that.loadingDialog = false;
                    that.dialogEditMember = false;
                    Swal.fire({
                      icon: "success",
                      title: "Success",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                  if (response2.data.status == 2) {
                    eventBus.$emit("logout");
                  }
                  that.getDataResidenceMember();
                  that.loadingDialog = false;
                })
                .catch(function (error) {
                  that.loadingDialog = false;
                  if (error.response.status != 404) {
                    that.loadingDialog = false;
                    Swal.fire({
                      icon: "error",
                      title: "Error...",
                      width: 900,
                      text: error.response.data.message,
                    });
                  }
                });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
          })
          .catch(function (error) {
            that.loadingDialog = false;
            if (error.response.status != 404) {
              that.loadingDialog = false;
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        // Update member
        requestData.personID = that.personID_edit;
        axios
          .post(`${that.url}Residence/updateResidenceMember`, requestData)
          .then(function (response) {
            if (response.data.status == 0) {
              that.loadingDialog = false;
              that.dialogEditMember = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getDataResidenceMember();
            that.loadingDialog = false;
          })
          .catch(function (error) {
            that.loadingDialog = false;
            if (error.response.status != 404) {
              that.loadingDialog = false;
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },
    getResidenceDropdownList() {
      let that = this;
      that.loadingDialog = true;
      axios
        .get(`${that.url}Residence/getResidenceDropdownList`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.residenceDropDownList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    uploadFiles() {
      let that = this;
      let requestData = {
        filesName: that.image.name,
        base64: that.base64,
      };
      axios
        .post(`${that.url}Residence/checkFrontalFaceImage`, requestData)
        .then(function (response) {
          if (response.data.status == 0) {
            if (that.imageList_edit.length > 0) {
              that.imageList_edit.forEach((element) => {
                element.isDefault = false;
              });
            }
            let dataImage = {
              name: response.data.data.imageName,
              base64: response.data.data.imageResponse,
              isDefault: true,
            };
            that.urlImg = dataImage.base64;
            that.imageList_edit.push(dataImage);
          }
          if (response.data.status == 1) {
            Swal.fire({
              icon: "warning",
              title: "รูปนี้ไม่เหมาะสมในการนำมาลงทะเบียน",
              showConfirmButton: true,
            });
            if (that.urlImg != null) {
              if (that.imageList_edit.length > 0) {
                that.urlImg = that.imageList_edit.find(
                  (a) => a.isDefault == true
                ).base64;
              } else {
                that.urlImg = null;
                that.image = null;
              }
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getCardResidenceDropdownList() {
      let that = this;
      axios
        .get(`${that.url}Residence/getCardResidenceDropdownList`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.cardResidenceDropdownList = response.data.data;
            that.loadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    // Dialog Edit Member
    openEditMemberDialog(type, data) {
      let that = this;
      that.loadingDialog = true;

      that.memberID = "";
      that.personID_edit = "";
      that.firstname_edit = "";
      that.lastname_edit = "";
      that.gender_edit = "";
      that.idcard_edit = "";
      that.passport_edit = "";
      that.note_edit = "";
      that.activeStatus_edit = true;
      that.activeStatus = that.activeStatus_edit;
      that.residenceIDList_edit = [];
      that.cardResidenceList_edit = [];
      that.email_edit = "";
      that.email_add = "";
      that.emailList_edit = [];
      that.countryCode_add = "";
      that.countryCode_edit = "";
      that.phone_add = "";
      that.phone_edit = "";
      that.phoneList_edit = [];
      that.image = null;
      that.base64 = null;
      that.urlImg = null;
      that.imageList_edit = [];
      that.cardNumber = "";
      that.cardResidenceDropdownList = [];

      if (type == "add") {
        that.statusEditMember = false;
      } else {
        that.statusEditMember = true;
        that.getDetailResidenceMemberByPersonID(data.personID);
      }
      that.dialogEditMember = true;
      that.loadingDialog = false;
    },
    closeEditMemberDialog() {
      let that = this;
      that.dialogEditMember = false;
      that.memberID = "";
      that.personID_edit = "";
      that.firstname_edit = "";
      that.lastname_edit = "";
      that.gender_edit = "";
      that.idcard_edit = "";
      that.passport_edit = "";
      that.note_edit = "";
      that.activeStatus_edit = true;
      that.activeStatus = that.activeStatus_edit;
      that.residenceIDList_edit = [];
      that.cardResidenceList_edit = [];
      that.email_edit = "";
      that.email_add = "";
      that.emailList_edit = [];
      that.countryCode_add = "";
      that.countryCode_edit = "";
      that.phone_add = "";
      that.phone_edit = "";
      that.phoneList_edit = [];
      that.image = null;
      that.base64 = null;
      that.urlImg = null;
      that.imageList_edit = [];
      that.cardNumber = "";
      that.cardResidenceDropdownList = [];
    },
    mapMemberResidenceData(data) {
      let that = this;
      that.memberID = data.id;
      that.personID_edit = data.personID;
      that.firstname_edit = data.firstname;
      that.lastname_edit = data.lastname;
      that.gender_edit =
        data.gender == "M" ? "Male" : data.gender == "F" ? "Female" : "";
      that.idcard_edit = data.idcard;
      that.passport_edit = data.passport;
      that.note_edit = data.note;
      that.activeStatus_edit = data.active == true ? data.active : false;
      that.activeStatus = that.activeStatus_edit;
      that.residenceIDList_edit =
        data.residentIDList.length == 0
          ? []
          : data.residentIDList.map((x) => x.residenceID);
      that.cardResidenceList_edit =
        data.cardResidenceList == null ? [] : data.cardResidenceList;
      that.emailList_edit = data.emailList == null ? [] : data.emailList;
      that.phoneList_edit = data.phoneList == null ? [] : data.phoneList;
      that.imageList_edit = data.imageList == null ? [] : data.imageList;

      // Get default data to shown
      that.emailList_edit?.forEach((element) => {
        if (element.isDefault == true) {
          that.email_edit = element.email;
        }
      });
      that.imageList_edit?.forEach((element) => {
        if (element.isDefault == true) {
          that.image = element.name;
          that.urlImg = element.base64;
        }
      });
      that.phoneList_edit?.forEach((element) => {
        if (element.isDefault == true) {
          that.countryCode_edit = element.code;
          that.phone_edit = element.phoneNumber;
        }
      });
    },

    // Open Dialog
    openDialog(dialogType, statusOpen) {
      let that = this;
      if (dialogType == "AddPhone") {
        // Phone
        if (statusOpen == true) {
          that.phone_add = "";
          that.countryCode_add = "";
          that.dialogAddPhone = true;
        } else {
          that.dialogAddPhone = false;
        }
      } else if (dialogType == "AddEmail") {
        // Email
        if (statusOpen == true) {
          that.email_add = "";
          that.dialogAddEmail = true;
        } else {
          that.dialogAddEmail = false;
        }
      } else {
        // Card Residence
        if (statusOpen == true) {
          that.dialogCardResidence = true;
          that.getCardResidenceDropdownList();
        } else {
          that.dialogCardResidence = false;
        }
      }
    },
    addPhone() {
      let that = this;
      if (
        that.countryCode_add == "" ||
        that.countryCode_add == null ||
        that.phone_add == "" ||
        that.phone_add == null
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        let temp = {
          code: that.countryCode_add,
          country:
            that.countryCodeJson.find((a) => a.code == that.countryCode_add) ==
            null
              ? ""
              : that.countryCodeJson.find((a) => a.code == that.countryCode_add)
                  .country,
          phoneNumber: that.phone_add,
          isDefault: that.phoneList_edit?.length == 0 ? true : false,
          active: true,
        };
        if (temp.isDefault == true) {
          that.countryCode_edit = temp.code;
          that.phone_edit = temp.phoneNumber;
        }
        that.phoneList_edit.push(temp);
        that.phone_add = "";
        that.countryCode_add = "";
        that.dialogAddPhone = false;
      }
    },
    addEmail() {
      let that = this;
      if (that.email_add == "" || that.email_add == null) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else if (
        that.email_add.split("@").length - 1 == 0 ||
        that.email_add.split("@").length - 1 > 1
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอก Email ให้ถูกต้อง (Example: aaa_b@gmail.com)",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        let temp = {
          email: that.email_add,
          isDefault: that.emailList_edit?.length == 0 ? true : false,
          active: true,
        };
        if (temp.isDefault == true) {
          that.email_edit = temp.email;
        }
        that.emailList_edit.push(temp);
        that.email_add = "";
        that.dialogAddEmail = false;
      }
    },
    addCardResidence(value) {
      let that = this;
      if (value != null) {
        var duplicateStatus = that.cardResidenceList_edit.find(
          (x) => x.cardID == value.cardID
        );
        if (duplicateStatus != null) {
          Swal.fire({
            icon: "warning",
            html:
              "พบข้อมูลการ์ดที่ซ้ำกัน : " +
              value.cardNumber +
              "<br>Found duplicate card : " +
              value.cardNumber,
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          var addedData = {
            cardID: value.cardID,
            cardNumber: value.cardNumber,
            startDate: value.startDate,
            endDate: value.expiredDate,
            residenceID: value.residenceID,
            active: value.active,
          };
          that.cardResidenceList_edit.push(addedData);
          if (
            that.cardResidenceList_edit.length > that.itemsPerPageCardResidence
          ) {
            that.no_runCardResidence =
              (that.pageCardResidence - 1) * that.itemsPerPageCardResidence;
          }
        }
      }
    },
    deleteItem(type, item) {
      let that = this;
      let temp = {};
      if (type == "Phone") {
        temp.onConfirm = function () {
          var index = that.phoneList_edit.indexOf(item);
          that.phoneList_edit.splice(index, 1);
          if (that.phoneList_edit.length == 0) {
            this.countryCode_edit = null;
            this.phone_edit = null;
          }
        };
      } else if (type == "Email") {
        temp.onConfirm = function () {
          var index = that.emailList_edit.indexOf(item);
          that.emailList_edit.splice(index, 1);
          if (that.emailList_edit.length == 0) {
            this.email_edit = null;
          }
        };
      } else if (type == "Image") {
        temp.onConfirm = function () {
          var index = that.imageList_edit.indexOf(item);
          that.imageList_edit.splice(index, 1);
          if (that.imageList.length == 0) {
            that.image = null;
            that.urlImg = null;
            that.base64 = null;
          }
        };
      } else if (type == "CardResidence") {
        temp.onConfirm = function () {
          var index = that.cardResidenceList_edit.indexOf(item);
          that.cardResidenceList_edit.splice(index, 1);
          that.getCardResidenceDropdownList();
        };
      } else {
        // Do nothing
      }
      that.deleteItemPopup(temp);
    },
    deleteItemPopup(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          item.onConfirm();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    isDefaultPhone(index) {
      this.phoneList_edit.forEach((element) => {
        element.isDefault = false;
      });

      this.phoneList_edit[index].isDefault = true;
      this.phoneList_edit[index].active = true;
      this.countryCode_edit = this.phoneList_edit[index].code;
      this.phone_edit = this.phoneList_edit[index].phoneNumber;
    },
    isDefaultEmail(index) {
      this.emailList_edit.forEach((element) => {
        element.isDefault = false;
      });

      this.emailList_edit[index].isDefault = true;
      this.emailList_edit[index].active = true;
      this.email_edit = this.emailList_edit[index].email;
    },
    isDefaultImage(index) {
      this.imageList_edit.forEach((element) => {
        element.isDefault = false;
      });

      this.imageList_edit[index].isDefault = true;
      this.urlImg = this.imageList_edit[index].base64;
      this.image = this.imageList_edit[index].name;
    },
    createBase64Image() {
      let that = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.name = this.image.name;
      reader.size = this.image.size;
      reader.onload = (event) => {
        var img = new Image();
        img.src = event.target.result;
        img.name = event.target.name;
        img.size = event.target.size;
        img.onload = function (el) {
          var elem = document.createElement("canvas");
          var scaleFactor = 400 / el.target.width;
          elem.width = 400;
          elem.height = el.target.height * scaleFactor;

          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          var srcEncoded = ctx.canvas.toDataURL("image/png", 1);
          that.base64 = srcEncoded;
          that.urlImg = that.base64;
          that.uploadFiles();
        };
      };
    },
    validateAddResidenceData() {
      let that = this;
      let statusValidate = false;
      let error_msg = "";
      if (that.firstname_edit.trim() == "" || that.firstname_edit == null) {
        error_msg =
          "กรุณากรอกข้อมูล 'ชื่อจริง' ให้ครบถ้วน<br>Please fill 'First name' information";
      } else if (
        that.lastname_edit.trim() == "" ||
        that.lastname_edit == null
      ) {
        error_msg =
          "กรุณากรอกข้อมูล 'นามสกุล' ให้ครบถ้วน<br>Please fill 'Last name' information";
      } else if (
        that.idcard_edit != "" &&
        that.idcard_edit.trim().length != 13
      ) {
        error_msg =
          "กรุณากรอก 'เลขบัตรประชาชน' ให้ถูกต้อง (ตัวเลข 13 หลัก)<br>Please enter your 'ID card number' correctly (consists of 13 digits)";
      } else if (
        that.passport_edit.trim() != "" &&
        that.passport_edit.trim().length < 6
      ) {
        error_msg =
          "กรุณากรอก 'เลขพาสปอร์ต' ให้ถูกต้อง (ตัวอักษร 6 ตัวขึ้นไป)<br>Please enter the correct 'Passport number' (6 or more characters)";
      } else {
        statusValidate = true;
      }
      return [statusValidate, error_msg];
    },

    // Common function
    changeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    changeFormatDateNoTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
    getColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    getColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },
    convertDate(value) {
      if (value != "" && value != null) {
        if (value.includes("T")) {
          return value.split("T")[0];
        }
      }
      return value;
    },
    toggleTooltip(index) {
      this.$set(this.tooltips, index, !this.tooltips[index]);
    },
    calculatePagination(lengthData, pageNumber, itemsPerPage) {
      let pageCount = Math.ceil(lengthData / itemsPerPage);
      let no_run = (pageNumber - 1) * itemsPerPage;
      return [pageCount, no_run];
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}

* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}

.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}

.validation-class {
  color: red;
  font-size: 12px;
}

.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}

.rect3 {
  animation-delay: -1.1s;
}

.rect4 {
  animation-delay: -1s;
}

.rect5 {
  animation-delay: -0.9s;
}

*
  >>> .v-input--selection-controls.v-input--is-disabled:not(
    .v-input--indeterminate
  )
  .v-icon {
  color: lightgrey;
}
</style>
