<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          <v-row>
            <v-col cols="6" md="6" lg="6" style="text-align: start">
              Report Payment
            </v-col>
            <v-col cols="6" md="6" lg="6" style="text-align: end"> </v-col>
          </v-row>
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-5">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-row>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-menu
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="startDate"
                                label="Start Date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="startDate"
                              @change="menuStartDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                          <v-menu
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="endDate"
                                label="End Date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="endDate"
                              @change="menuEndDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <!-- <v-col cols="12" sm="3" md="3" lg="3" class="pt-5">
                          <v-autocomplete
                            :items="StatusPaymentData"
                            item-value="id"
                            item-text="status"
                            label="Status"
                            v-model="StatusPayment"
                            required
                            clearable
                            outlined
                            multiple
                            dense
                          ></v-autocomplete>
                        </v-col> -->
                        <!-- <v-col cols="12" sm="3" md="3" lg="3" class="pt-5">
                          <v-text-field
                            label="Search"
                            append-icon="mdi-database-search-outline"
                            v-model="ticketNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col> -->
                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                          class="mt-1"
                          style="text-align: end"
                        >
                          <v-btn
                            :color="'green'"
                            class="ma-2 white--text"
                            @click="OpenLogAdjust()"
                          >
                            Adjust Transaction

                            <v-icon right>
                              mdi-credit-card-clock-outline
                            </v-icon>
                          </v-btn>
                          <v-btn
                            :color="'blue'"
                            class="ma-2 white--text"
                            @click="getTransactionDiff()"
                          >
                            Search

                            <v-icon right> mdi-magnify </v-icon>
                          </v-btn>
                          <!-- <vue-excel-xlsx
                              :data="dataExport"
                              :columns="columns"
                              :file-name="'Transaction Carpark Log ' + Date.now()"
                              :file-type="'xlsx'"
                              :sheet-name="'Transaction Carpark'"
                            >
                              <v-btn color="orange" class="ma-2 white--text">
                                Export Excel
                                <v-icon right>mdi-file-excel-outline</v-icon>
                              </v-btn>
                            </vue-excel-xlsx> -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="
                padding-left: 2%;
                margin-top: 2%;
                margin-bottom: 2%;
                padding-right: 2%;
              "
            >
              <v-row>
                <v-col cols="12" md="6"></v-col>
                <v-col cols="12" md="6" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="8"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="DataList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-center-css">
                      {{ no_run + (index + 1) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.merchantName }}
                    </td>
                    <!-- <td class="header-table-center-css">
                      {{ item.reportName }}
                    </td> -->
                    <td class="header-table-center-css">
                      {{ ChangeFormatDateOnly(item.transactionDate) }}
                    </td>
                    <td class="header-table-center-css">
                      <template>
                        <v-row align="center" justify="space-around">
                          <v-btn
                            depressed
                            style="background-color: skyblue; color: white"
                            @click="Report502(item.transactionDate)"
                          >
                            Report 502
                          </v-btn>
                          <v-btn
                            depressed
                            color="primary"
                            @click="Report503(item.transactionDate)"
                          >
                           Report 503
                          </v-btn>
                        </v-row>
                      </template>
                    </td>
                    <td style="text-align: center; min-width: 150px">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="pink"
                            v-bind="attrs"
                            v-on="on"
                            @click="sendSap(item.transactionDate)"
                          >
                            <v-icon dark> mdi-alpha-s-box-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>SAP</span>
                      </v-tooltip>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="blue"
                        @click="OpenChartDialog(item.transactionDate)"
                      >
                        <v-icon dark> mdi-poll </v-icon>
                      </v-btn>

                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click="ExportDatePayment(item.transactionDate)"
                          >
                            <v-icon dark> mdi-file-excel-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Export Excel</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-model="TransactionCarparkDialog"
      persistent
      max-width="1100px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Carpark Transaction</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row style="margin-top: 1%">
              <v-col cols="12" md="6" sm="6">
                <v-card class="mt-3 mx-auto" height="150">
                  <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="green"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                  >
                    <v-card-title
                      style="
                        color: white;
                        display: flex;
                        font-size: 18px;
                        justify-content: space-between;
                      "
                    >
                      <strong>License Plate</strong>Vehicle Type :
                      <div v-if="DataListCarparkID.vehicleType == 'Motorcycle'">
                        <v-icon style="color: white; font-size: 30px"
                          >mdi-motorbike</v-icon
                        >
                        <span class="ml-3">Motorcycle</span>
                      </div>
                      <div v-if="DataListCarparkID.vehicleType == 'Car'">
                        <v-icon style="color: white; font-size: 30px"
                          >mdi-car-estate</v-icon
                        >
                        <span class="ml-3">Car</span>
                      </div>
                    </v-card-title>
                  </v-sheet>

                  <v-card-subtitle>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="text-align: center; font-size: 60px"
                      >
                        <strong> {{ DataListCarparkID.carNo }}</strong>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-card class="mt-3 mx-auto" height="150">
                  <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="blue"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                    height="70"
                  >
                    <v-card-title
                      style="
                        color: white;
                        display: flex;
                        font-size: 18px;
                        justify-content: space-between;
                      "
                    >
                      <strong>Transaction Entry Log</strong>
                      Status :
                      {{
                        DataListCarparkID.exitStatus == 0
                          ? "Still Remain"
                          : "Exit"
                      }}
                    </v-card-title>
                  </v-sheet>

                  <v-card-subtitle>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="text-align: center; font-size: 30px"
                      >
                        <strong> {{ DataListCarparkID.logID }}</strong>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card class="mt-3 mx-auto" elevation="10">
                  <v-card-title class="text-h6">
                    <strong>Parking Information</strong>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <span style="font-size: 18px; color: black"
                          >Entry Log: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.logID
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black"
                          >Exit Log: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.logIDExit
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black"
                          >Ticket No: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.ticketNo
                          }}</span></span
                        ><br />
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <span style="font-size: 18px; color: black">
                          Entry Date: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.startDate
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black">
                          Exit Date: &nbsp;<span style="color: #666666">{{
                            ChangeFormatDate(DataListCarparkID.endDate)
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black">
                          Parking Time: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.parkTime
                          }}</span></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="ImageList.length != 0">
                <v-card class="mt-3 mx-auto" elevation="10">
                  <v-card-title class="text-h6">
                    <strong>Parking Images</strong>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row
                      style="display: flex"
                      v-for="item in ImageList"
                      :key="item.id"
                    >
                      <v-col cols="12" sm="12" md="12">
                        <div style="font-size: large">
                          Images {{ item.logType }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.driverImage != ''"
                      >
                        <v-img
                          :src="item.driverImage"
                          @click="OpenImageCarpark(item.driverImage)"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.licensePlateImage != ''"
                      >
                        <v-img
                          :src="item.licensePlateImage"
                          @click="OpenImageCarpark(item.licensePlateImage)"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.lpR_Image != ''"
                      >
                        <v-img
                          :src="item.lpR_Image"
                          @click="OpenImageCarpark(item.lpR_Image)"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-row style="text-align: -webkit-center"> </v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: right; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="permissionsPage.delete"
                variant="outlined"
                color="primary"
                style="min-width: 150px; color: white; margin-right: 20px"
                append-icon="mdi-close-circle-outline"
                @click="OpenQrImage(DataListCarparkID.logID)"
              >
                Reprint
              </v-btn>
              <v-btn
                variant="outlined"
                color="red"
                style="min-width: 150px; color: white"
                append-icon="mdi-close-circle-outline"
                @click="TransactionCarparkDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ShowImageDialog" persistent width="1100">
      <v-card>
        <v-card-text>
          <v-col cols="12" md="12" sm="12">
            <v-img :src="ShowImageText"></v-img>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: center; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseImageCarpark()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="ChartDialog" persistent width="1100">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Report : {{ DateSelected }}
        </v-card-title>
        <v-card-text>
          <v-col cols="6" md="6" sm="6">
            <apexchart type="radialBar" height="390" :options="chartOptions" :series="series"></apexchart>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <apexchart type="radialBar" height="390" :options="chartOptions" :series="series"></apexchart>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: center; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="ChartDialog = false"
              >
                Done
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="ChartDialog" persistent max-width="1200px">
      <v-card>
        <v-card-title
          style="background-color: black; color: white; padding: 5px"
        >
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span class="text-h5"> Report : {{ DateSelected }}</span>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row style="margin-top: 3%">
                  <v-col cols="6" sm="6" md="6">
                    <v-card class="mt-3 mx-auto" height="200">
                      <v-sheet
                        class="v-sheet--offset mx-auto"
                        color="green"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                      >
                        <v-card-title
                          style="
                            color: white;
                            display: flex;
                            font-size: 18px;
                            justify-content: center;
                          "
                        >
                          <strong>Argento</strong>
                        </v-card-title>
                      </v-sheet>

                      <v-card-subtitle>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            style="text-align: center; font-size: 40px"
                          >
                            Transaction :
                            {{ DataAllTransaction.transactionArgento }}
                            <br /><br />

                            Amount : {{ DataAllTransaction.amountArgento }}
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-card class="mt-3 mx-auto" height="200">
                      <v-sheet
                        class="v-sheet--offset mx-auto"
                        color="pink"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                      >
                        <v-card-title
                          style="
                            color: white;
                            display: flex;
                            font-size: 18px;
                            justify-content: center;
                          "
                        >
                          <strong>WebHook</strong>
                        </v-card-title>
                      </v-sheet>

                      <v-card-subtitle>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            style="text-align: center; font-size: 40px"
                          >
                            Transaction :
                            {{ DataAllTransaction.transactionWebHook }}
                            <br /><br />

                            Amount : {{ DataAllTransaction.amountWebHook }}
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="12">
                    <v-card class="mt-3 mx-auto" height="250">
                      <v-sheet
                        class="v-sheet--offset mx-auto"
                        color="blue"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                      >
                        <v-card-title
                          style="
                            color: white;
                            display: flex;
                            font-size: 18px;
                            justify-content: center;
                          "
                        >
                          <strong>Forward System</strong>
                        </v-card-title>
                      </v-sheet>

                      <v-card-subtitle>
                        <v-row>
                          <v-col
                            cols="6"
                            md="6"
                            sm="6"
                            style="text-align: center; font-size: 40px"
                          >
                          Transaction : {{ DataAllTransaction.transactionArgento }} <br /><br />

                          Amount : {{ DataAllTransaction.amountArgento }}
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="6"
                            style="text-align: center; font-size: 40px"
                          >
                          Transaction : {{ DataAllTransaction.transactionArgento }} <br /><br />

                          Amount : {{ DataAllTransaction.amountArgento }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            sm="12"
                            style="
                              text-align: center;
                              font-size: 40px;
                              margin-top: 20px;
                            "
                          >
                          Amount : {{ DataAllTransaction.amountArgento }}
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>

              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="ChartDialog = false"
              >
                Done
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogReprint" persistent scrollable max-width="1200px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Reprint Transaction</span>
        </v-card-title>
        <v-card-text>
          <ReprintComponent />
        </v-card-text>

        <v-card-actions style="background-color: #f9f9f9">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 10px; margin-top: 10px"
            >
              <v-spacer></v-spacer>

              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px; background-color: white"
                append-icon="mdi-close-circle-outline"
                @click="DialogReprint = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import enurl from "../../../public/environment";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReprintComponent from "@/components/ReprintComponent";
import html2canvas from "html2canvas";
import { eventBus } from "../../main";

export default {
  name: "ReprintPayment",
  components: {
    ReprintComponent,
  },
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      urlCarpark: enurl.apiCarparkUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      ChartDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      DataList: [],
      DataListCarparkID: [],
      DataListImgID: [],
      ConfigTerminal: [],
      DropdownConfigTerminal: [],
      DropdownTerminalIn: [],
      terminalIDIn: null,
      terminalIDOut: null,
      DropdownTerminalOut: [],
      ImageList: [],

      DriverImg: "",
      LicensePlateImg: "",
      LPRImg: "",

      search: "",
      activeStatus: null,
      startDate: "",
      menuStartDate: false,
      menuEndDate: false,
      TransactionCarparkDialog: false,
      EditStatus: false,
      ShowImageDialog: false,
      ShowReprintImageDialog: false,
      DialogReprint: false,
      ShowImageText: "",
      // Add Edit

      Status: true,

      searchBy: "",
      listType: "",
      text: "",
      vmsKiosk: "",
      stamp: "",

      logEntry: "",
      carNo: "",
      endDate: "",
      vehicleType: "",
      ticketNo: "",
      terminalEntry: "",
      statusLog: "",
      password: "",

      permissionsPage: [],
      ExportButtonRolePermission: null,
      tenantArray: [],
      StatusPayment: "",
      dataExport: [],
      AlldataDetailsReceipt: [],
      DetailsReceipt: [],
      DataAllTransaction: [],
      Receiptmodel: "",

      StatusPaymentData: [
        {
          id: "2",
          status: "Paid",
        },
        {
          id: "3",
          status: "Cancel",
        },
      ],

      logId: "",
      qrImage: "",
      ticket: "",
      license: "",
      date: "",
      time: "",
      DateSelected: "",

      dialogText:
        "Parking Payment Options<br/>1. Mobile Payment Scan QR code through mobile camera<br/>or One Bangkok application<br/>2. Kiosk Payment Scan QR cade at parking payment kiosk located at retail area<br/><br/>วิธีชำระค่าบริการ<br/>1. Mobile Payment สแกนQR codeด้านบนผ่านโทรศัพท์มือถือ หรือ<br/>One Bangkok แอพพลิเคชั่น<br/>2. Kiosk Payment นำบัตรจอดรถแสกนที่ตู้อัตโนมัติภายในศูนย์ฯ",

      columns: [
        {
          label: "",
          field: "LogEntry",
        },
        {
          label: "",
          field: "LicensePlate",
        },
        {
          label: "",
          field: "Tenant",
        },
        {
          label: "",
          field: "DatetimeIn",
        },
        {
          label: "",
          field: "DatetimeOut",
        },
        {
          label: "",
          field: "VehicleType",
        },
        {
          label: "",
          field: "TicketNo",
        },
        {
          label: "",
          field: "TerminalIn",
        },
        {
          label: "",
          field: "TerminalOut",
        },
      ],

      ///chart data
      series: [0, 0, 0, 0],
      chartOptions: {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              offsetX: -8,
              fontSize: "16px",
              formatter: function (seriesName, opts) {
                return (
                  seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                );
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        labels: ["Vimeo", "Messenger", "Facebook", "LinkedIn"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Merchant Name",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Report Name",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "Transaction Date",
          align: "center",
          sortable: false,
        },
        {
          text: "Report",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    await self.getTransactionDiff();
    // await self.getMasConfigTerminal();
    // await self.getTennant();
  },

  methods: {
    getParkingDetailReceipt(logID, description) {
      let self = this;
      logID = logID.replace(/(?:\\[rn])+/g, "");
      let type = 0;
      if (description != "Thai Qr") {
        type = 1;
      }
      let tempdata = {
        logid: logID,
        type: type,
      };
      axios
        .post(`${self.urlCarpark}Redemption/AlldataDetailsReceipt`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            console.log(response.data);
            self.AlldataDetailsReceipt = response.data.data;
            self.DetailsReceipt = response.data.data.data;
            self.ShowReprintImageDialog = true;

            // console.log(self.AlldataDetailsReceipt);
            // self.chooseData(self.AlldataDetailsReceipt[0].trn_Log_ID_Payment);
          }
          if (response.data.status == 1) {
            self.overlay = false;
            // self.defaultPage = true;
            // self.$router.push("/ParkingPayment/" + 0);
          }
        })
        .catch(function (error) {
          self.MessageAlert = error;
          self.TypeAlert = "error";
          self.AlertDialog = true;
        });
    },
    print() {
      let self = this;
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        // document.body.appendChild(canvas)
        // console.log(document.body.appendChild(canvas));

        const base64 = canvas.toDataURL("image/png");

        // var myImage = canvas.toDataURL("image/png");
        var tWindow = window.open("");

        tWindow.document.body.appendChild(canvas);

        // focus on the window
        tWindow.focus();

        // print the window
        tWindow.print();

        // reload the page
        tWindow.close();

        // console.log(base64);
        self.base64 = base64;
      });
    },

    OpenLogAdjust() {
      let that = this;
      Swal.fire({
        title: "กรอก Adjust Transaction",
        html: `
          <input id="swal-input1" class="swal2-input" placeholder="ARG........... ,etc"> <br><br>

        `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          let tranNo = document.getElementById("swal-input1").value;
          that.AdjustPayment(tranNo);
        }
      });
    },

    OpenQrImageindex(data) {
      let self = this;
      if (data != "") {
        // self.ShowImageText = data;
        self.qrImage = "https://carpark.onebangkok.com/info/" + data.logEntry;
        self.logId = data.logEntry;
        self.ticket = data.ticketNo;
        self.license = data.carNo;
        self.date = self.ChangeFormatDateOnly(data.startDate);
        self.time = self.ChangeFormatTime(data.startDate);
        self.ShowReprintImageDialog = true;
      }
    },

    OpenCardDialogReprint() {
      let self = this;
      self.DialogReprint = true;
    },

    OpenQrImage(data) {
      let self = this;
      if (data != "") {
        // self.ShowImageText = data;
        self.ShowReprintImageDialog = true;
      }
    },

    async OpenChartDialog(date) {
      let that = this;
      that.DateSelected = date;
      that.LoadingDialog = true;

      let temp = {
        Date: date,
      };
      await axios
        .post(`${that.urlCarpark}PaymentGateway/getAlltransaction`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // that.ConfigTerminal = response.data.data;
            console.log(response.data.data);
            that.DataAllTransaction = response.data.data;
            that.ChartDialog = true;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    OpenImageCarpark(data) {
      let self = this;
      if (data != "") {
        self.ShowImageText = data;
        self.ShowImageDialog = true;
      }
    },

    CloseImageCarpark() {
      let self = this;
      self.ShowImageText = "";
      self.ShowImageDialog = false;
    },

    getTennant() {
      let self = this;
      axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getMasConfigTerminal() {
      let that = this;
      await axios
        .get(`${that.url}TransactionCarpark/getMasConfigTerminal`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ConfigTerminal = response.data.data;
            // console.log(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ConvertTerminalID(value) {
      let self = this;
      if (value != null) {
        return self.ConfigTerminal.filter((x) => x.terminalID == value) == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0] == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0]
              .terminalName;
      }
    },

    async getTransactionDiff() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        pageSize: 10,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
      };
      await axios
        .post(`${self.urlCarpark}PaymentGateway/getTransactionDiff`, temp)
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.data;
            self.pageCount = response.data.totalPages;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async AdjustPayment(tranNo) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        TransactionNo: tranNo,
      };
      await axios
        .post(`${self.urlCarpark}PaymentGateway/AdjustPayment`, temp)
        .then(function (response) {
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async ExportDatePayment(value) {
      let self = this;
      self.DateSelected = value;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        Date: value,
      };
      await axios
        .post(`${self.urlCarpark}PaymentGateway/ExportDatePayment`, temp, {
          responseType: "blob", // แจ้ง Axios ว่าต้องการรับข้อมูลในรูปแบบ Blob (ไฟล์)
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // สร้างลิงก์ในการดาวน์โหลดไฟล์
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "รายงานค่าจอดรถรายวันที่ " + self.DateSelected + ".xlsx"
          ); // ตั้งชื่อไฟล์ที่ดาวน์โหลด
          document.body.appendChild(link);
          link.click(); // คลิกเพื่อดาวน์โหลดไฟล์
          window.URL.revokeObjectURL(url);
          self.LoadingDialog = false;
        })
        .catch((err) => console.error("Download failed:", err));
    },
    async sendSap(date) {
      let that = this;
      that.DateSelected = date;
      that.LoadingDialog = true;
      let dateString = that.ChangeFormatDateOnly(date);
      Swal.fire({
        title: "SAP ของวันที่ " + dateString,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Send SAP",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let temp = {
            selectedDate: date,
          };
          axios
            .post(`${that.urlCarpark}PaymentGateway/ExportSAPManual`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                Swal.fire(response.data.message, "", "success");
                that.LoadingDialog = false;
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
        } else {
          that.LoadingDialog = false;
        }
      });
    },

    Report502(date) {
      window.open(
        "http://10.144.52.130/report_pages/Default.aspx?Guid=0b6580c6-d9ed-4953-b98e-3d5a7db37e53&start=" +
          date +
          "T00%3a00%3a00.0000000&end="+date+"T00%3a00%3a00.0000000&rptFile=rptTAX_Detail.rpt&rptTitle=%e0%b8%a3%e0%b8%b2%e0%b8%a2%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%a0%e0%b8%b2%e0%b8%a9%e0%b8%b5%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b9%81%e0%b8%9a%e0%b8%9a%e0%b8%a5%e0%b8%b0%e0%b9%80%e0%b8%ad%e0%b8%b5%e0%b8%a2%e0%b8%94&rptID=0502&TermIDs=1%2c2%2c3%2c4%2c5%2c6%2c7%2c8%2c9%2c10%2c11%2c12%2c14%2c15%2c16%2c17%2c18%2c19%2c20%2c21%2c22%2c23%2c24%2c25%2c26%2c51%2c52%2c53%2c54%2c55%2c56%2c57%2c58%2c59%2c60%2c61%2c62%2c63%2c64%2c65%2c67%2c69%2c70%2c71%2c72%2c73%2c74%2c75%2c76%2c77%2c78%2c91%2c92%2c100%2c301%2c401%2c402%2c403%2c404%2c405%2c406%2c407%2c408%2c409%2c410%2c411%2c412%2c413%2c414%2c415%2c416%2c417%2c418%2c419%2c420&AllTerminal=False",
        "_blank"
      );
    },
    Report503(date) {
      console.log(date);
      window.open(
        "http://10.144.52.130/report_pages/Default.aspx?Guid=8b82d975-ca79-47e4-9ac1-fc71c42b1a51&start=" +
          date +
          "T00%3a00%3a00.0000000&end=" +
          date +
          "T23%3a59%3a59.0000000&rptFile=SummaryPaymentDailyReport.rpt&rptTitle=%e0%b8%a3%e0%b8%b2%e0%b8%a2%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%aa%e0%b8%a3%e0%b8%b8%e0%b8%9b%e0%b8%a3%e0%b8%b2%e0%b8%a2%e0%b9%84%e0%b8%94%e0%b9%89%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%88%e0%b8%b3%e0%b8%a7%e0%b8%b1%e0%b8%99&rptID=0503&PaymentTypeIDs=&RevenueTypeIDs=2%2c4%2c5&TermIDs=&AllTerminal=False",
        "_blank"
      );
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: self.page,
        pageSize: self.itemsPerPage,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
      };
      axios
        .post(`${self.urlCarpark}PaymentGateway/getTransactionDiff`, temp)
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.data;
            self.pageCount = response.data.totalPages;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.itemsPerPage = value;
      let temp = {
        page: self.page,
        pageSize: value,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
      };
      axios
        .post(`${self.urlCarpark}PaymentGateway/getTransactionDiff`, temp)
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.data;
            self.pageCount = response.data.totalPages;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    ChangeFormatDateOnly(date) {
      if (!date) return null;
      var DateData = new Date(date);
      // // var hours = DateData.getHours();
      // var minutes = DateData.getMinutes();
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
    ChangeFormatTime(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var sec = DateData.getSeconds();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + ":" + sec;
      return strTime;
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    async OpenDialogForce(log) {
      this.LoadingDialog = true;
      const { value: formValues } = await Swal.fire({
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#3085d6",
        title: "ปรับสถานะออก",
        html: `
                <input id="swal-input1" type="password" class="swal2-input" placeholder="Password">
                <p style="color: red;text-align: center;">*กรอกรหัสยืนยันตัวตน </p>

              `,
        focusConfirm: false,
        preConfirm: () => {
          return [document.getElementById("swal-input1").value];
        },
      });
      if (formValues) {
        // console.log(formValues);
        this.password = formValues[0];
      }
      if (this.password == "") {
        this.LoadingDialog = false;
        // Swal.fire({
        //   icon: "warning",
        //   title: "Please fill in this form.",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        return;
      } else {
        this.forceAntiPassBack(log);
      }
    },

    forceAntiPassBack(log) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        logEntry: log,
        password: that.password,
      };
      axios
        .post(`${that.url}TransactionCarpark/forceAntiPassBack`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            that.getTransactionDiff();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      that.LoadingDialog = false;
    },

    getTransactionByLogID(id) {
      let that = this;
      that.LoadingDialog = true;
      that.DataListCarparkID = [];
      that.DataListImgID = [];
      let temp = {
        logid: id,
      };
      axios
        .post(`${that.urlCarpark}PaymentGateway/getTransactionByLogID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DataListCarparkID = response.data.data.transaction;
            // that.DataListImgID = response.data.data.picture;
            that.BindingEditData(that.DataListCarparkID);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    BindingEditData(transaction) {
      let that = this;

      that.qrImage = "https://carpark.onebangkok.com/info/" + transaction.logID;
      that.logId = transaction.logID;
      that.ticket = transaction.ticketNo;
      that.license = transaction.carNo;

      that.date = that.ChangeFormatDateOnly(transaction.logDate);
      that.time = that.ChangeFormatTime(transaction.startDate);

      that.DataListCarparkID.vehicleType =
        transaction.vehicleType == "0" ? "Car" : "Motorcycle";
      that.DataListCarparkID.logDate = that.ChangeFormatDate(
        transaction.logDate
      );
      that.DataListCarparkID.startDate = that.ChangeFormatDate(
        transaction.startDate
      );
      that.DataListCarparkID.parkTime =
        transaction.parkTime == ":" ? "" : transaction.parkTime;
      // console.log(picture);
      // if (picture.length > 0) {
      //   that.ImageList = picture;
      // }
      that.TransactionCarparkDialog = true;
      that.getTransactionByLogIDImage(that.logId);
    },

    getTransactionByLogIDImage(id) {
      let that = this;
      that.DataListImgID = [];
      that.ImageList = [];
      let temp = {
        logid: id,
      };
      axios
        .post(
          `${that.urlCarpark}PaymentGateway/getTransactionImagesByLogID`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            // that.DataListCarparkID = response.data.data.transaction;
            that.DataListImgID = response.data.data;
            // console.log(that.DataListImgID);
            if (that.DataListImgID.length > 0) {
              that.ImageList = that.DataListImgID;
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-center-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
