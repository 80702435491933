<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Cards Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchCard(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Expire', 'NotExpire']"
                    label="Status Expired"
                    v-model="expireStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Use', 'Not Use']"
                    label="Status Use"
                    v-model="useStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getCardList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenCardDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="UserID == 1 ? headersAdmin : headers"
                :items="CardList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.cardNumber }}</td>
                    <td class="header-table-css">{{ item.owner }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.startDate) }}
                    </td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.expireDate) }}
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.expire == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.use == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.useFace == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td class="header-table-css" v-if="UserID == 1">
                      {{ ChangeFormatDate(item.updateTime) }}
                    </td>
                    <td class="header-table-css" v-if="UserID == 1">
                      {{ item.updateBy }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 100px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenCardDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveCardByCardID(item.cardID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4"></v-col>

                <v-col cols="12" md="4"></v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="CardDialog" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: black; color: white; padding: 5px">
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span v-if="EditStatus" class="text-h5">Edit Card</span>
            <span v-else class="text-h5">Create Card</span>
          </v-col>
          <v-col cols="6" sm="6" md="6" style="text-align: end">
            <v-tooltip bottom color="white" v-if="EditStatus">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <v-row>
                <v-col cols="12" sm="12" md="12" style="font-size: 15px; color: black">
                  Create By : {{ createBy }} &nbsp; {{ ChangeFormatDate(createTime) }}<br />
                  Update By : {{ updateBy }} &nbsp; {{ ChangeFormatDate(updateTime) }}<br />
                </v-col>
              </v-row>
            </v-tooltip>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Card Number*"
                      v-model="cardNumber"
                      type="text"
                      required
                      :disabled="EditStatus"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-btn
                      variant="outlined"
                      color="primary"
                      style="min-width: 100px"
                      @click="GenerateCardNumer()"
                    >
                      Virtual Card
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuStartDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateEvent"
                          label="Start Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDateEvent"
                        @change="menuStartDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuExpireDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ExpireDateEvent"
                          label="Expire Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ExpireDateEvent"
                        @change="menuExpireDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-switch
                      v-model="useFace"
                      hide-details
                      inset
                      :label="`UseFace`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" md="4"></v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-switch
                      v-model="active"
                      hide-details
                      inset
                      :label="active ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <p
                  class="pt-5"
                  style="color: #444444; font-size: 18px; font-weight: regular"
                >
                  Access
                </p>
                <v-row>
                  <v-autocomplete
                    ref="inputCardNumber"
                    v-model="SearchData"
                    :items="getCardsAccessGroup"
                    item-text="tagrP_Name"
                    item-value="accessID"
                    color="#126496"
                    prepend-inner-icon="mdi-magnify"
                    cols="12"
                    sm="6"
                    md="3"
                    outlined
                    return-object
                    dense
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-row style="height: 50px; margin-top: -0.5rem">
                            <v-col>
                              <v-list-item-title
                                v-html="data.item.tagrP_Name"
                              ></v-list-item-title>
                            </v-col>
                            <v-col class="text-end">
                              <v-btn
                                text
                                style="
                                  background-color: #126496;
                                  color: #ffffff;
                                  text-transform: none !important;
                                  margin-top: -0.65rem;
                                "
                                @click="AddDataDropdown(data.item)"
                                >Add</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                <!-- <v-data-table
                  :headers="headersDropdown"
                  :items="cardAccessGroupList"
                  hide-default-footer
                  :items-per-page="5"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>
                        {{ props.item.projectName }}
                      </td>
                      <td>{{ props.item.towerName }}</td>
                      <td>{{ props.item.tagGroupName }}</td>
                      <td>
                        <v-layout justify-center>
                          <v-btn text @click="DeleteDataDropdown(props.item)">
                            <img
                              src="@/assets/close.png"
                              width="15"
                              height="15"
                            />
                            <span
                              style="color: #ff4839"
                              class="ml-1 text-capitalize"
                              >Delete</span
                            >
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div class="text-center">
                  <v-pagination
                    v-model="pageFloor"
                    :length="lengthFloor"
                    :total-visible="5"
                    @input="ChangeFloorPage(pageFloor)"
                  ></v-pagination>
                </div> -->

                <v-data-table
                  :headers="headersDropdown"
                  :items="cardAccessGroupList"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.no="{ item }">
                    {{ cardAccessGroupList.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn text @click="DeleteDataDropdown(item)">
                      <img src="@/assets/close.png" width="15" height="15" />
                      <span style="color: #ff4839" class="ml-1 text-capitalize"
                        >Delete</span
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CardDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "CardManage",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      active: false,
      useFace: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      cardNumber: "",
      cardId: "",
      CardList: [],
      getCardsAccessGroup: [],
      getCardsAccessGroupList: [],
      search: "",
      searchAccess: "",
      tagGroupName: "",

      activeStatus: null,
      useStatus: null,
      expireStatus: null,

      startDate: "",
      endDate: "",
      startDateEvent: "",
      ExpireDateEvent: "",
      menuStartDate: false,
      menuEndDate: false,
      menuStartDateEvent: false,
      menuExpireDateEvent: false,
      CardDialog: false,
      EditStatus: false,
      ChooseLocationBTN: false,
      UserID: null,
      // Add Edit
      cardID: 0,
      CardName: "",
      CardNameTH: "",
      Status: true,

      pageFloor: 1,
      itemFloor: 10,
      lengthFloor: 1,
      totalPage: 10,

      SearchData: "",
      cardAccessGroupList: [],
      cardAccessGroupListTemp: [],
      InputListDataDropdown: null,
      permissionsPage: [],

      createBy: "",
      createTime: "",
      updateBy: "",
      updateTime: "",

      headersDropdown: [
        {
          text: "No",
          align: "center",
          value: "no",
        },
        {
          text: "Project Name",
          align: "left",
          value: "projectName",
          sortable: false,
        },
        {
          text: "Tower Name",
          align: "left",
          value: "towerName",
          sortable: false,
        },
        {
          text: "Tag GroupName",
          align: "center",
          value: "tagGroupName",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },
        {
          text: "Start Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Expired Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "Use Face",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Status",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersAdmin() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },
        {
          text: "Start Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Expired Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "Use Face",
          align: "center",
          sortable: false,
        },
        {
          text: "LastUpdateTime",
          align: "left",
          sortable: false,
        },
        {
          text: "LastUpdateBy",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersAccess() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "UseFace",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    InputListDataDropdown: function (data) {
      let self = this;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.$refs.refSearchData.focus();
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchData() {
      this.$refs.inputCardNumber.reset();
    },
  },

  mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    self.getCardList();
    self.GetCardsAccessGroup();
  },

  methods: {
    GenerateCardNumer() {
      let self = this;
      axios
        .get(`${self.url}Card/generateCardNumber`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.cardNumber = response.data.data;
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    GetCardsAccessGroup() {
      let that = this;
      that.LoadingDialog = true;

      axios
        .get(`${that.url}Card/getCardsAccessGroup`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getCardsAccessGroup = response.data.data;

            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getCardList() {
      let self = this;
      self.LoadingDialog = true;
      // self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchCard(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;
      if (that.CardName == "" || that.CardNameTH == "") {
        return true;
      } else {
        return false;
      }
    },

    addCard() {
      let that = this;
      // if (that.checkVakidates()) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }
      that.LoadingDialog = true;

      if (!that.EditStatus) {
        let temp = {
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          useFace: that.useFace,
          cardAccessGroupList: that.cardAccessGroupList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/addCard`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.page = 1;
            that.getCardList();
            that.CardDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          cardID: that.cardID,
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          useFace: that.useFace,
          cardAccessGroupList: that.cardAccessGroupList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/editCard`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getCardList();
            that.CardDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    // GetStatue(value) {
    //   if (value == 0) {
    //     return "Completed";
    //   } else if (value == 1) {
    //     return "process";
    //   } else {
    //     return "Error";
    //   }
    // },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenCardDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        self.cardNumber = "";
        self.startDateEvent = "";
        self.ExpireDateEvent = "";
        self.cardAccessGroupList = [];
        self.useFace = true;
        self.active = true;
        self.CardDialog = true;
      } else {
        self.getDataCardByCardID(data.cardID);
      }
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.cardID = data.cardID;
      self.cardNumber = data.cardNumber;
      self.startDateEvent =
        data.startDate == null ? "" : self.ConvertDate(data.startDate);
      self.ExpireDateEvent =
        data.expiredDate == null ? "" : self.ConvertDate(data.expiredDate);
      self.cardAccessGroupList = data.cardsAccessGroups;
      self.cardAccessGroupListTemp = data.cardsAccessGroups;
      self.tagGroupName = data.tagGroupName;
      self.useFace = data.useFace;
      self.active = data.active;
      self.createBy = data.createBy;
      self.createTime = data.createTime;
      self.updateBy = data.updateBy;
      self.updateTime = data.updateTime;
      self.CardDialog = true;
    },

    getDataCardByCardID(id) {
      let that = this;
      let temp = {
        cardID: id,
      };
      axios
        .post(`${that.url}Card/getDataCardByCardID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.BindingEditData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inActiveCardByCardID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        cardID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Card ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Card/inActiveCardByCardID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getCardList();
                Swal.fire(
                  "Deleted!",
                  "Your card has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    AddDataDropdown(val) {
      let self = this;
      if (val != null) {
        var CheckDuplicate = this.cardAccessGroupList.find(
          (x) => x.accessID == val.accessID
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            accessID: val.accessID,
            projectID: val.projectID,
            projectName: val.projectName,
            cardNumber: self.cardNumber,
            towerID: val.towerID,
            towerName: val.towerName,
            tagGroupNo: val.tagrP_NO,
            tagGroupName: val.tagrP_Name,
            active: true,
          };
          self.cardAccessGroupList.push(temp);
          self.cardAccessGroupListTemp = self.cardAccessGroupList;
          self.lengthFloor = Math.ceil(self.cardAccessGroupList.length / 5);
        }
      }
    },

    DeleteDataDropdown(item) {
      let self = this;
      // var del = Swal.fire("Are you sure you want to delete !");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.cardAccessGroupList.indexOf(item);
          self.cardAccessGroupList.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    ChangeFloorPage(value) {
      let that = this;
      that.cardAccessGroupList = that.paginate(
        that.cardAccessGroupListTemp,
        5,
        value
      );
    },
    ConvertDate(value) {
      if (value != "") {
        return value.split("T")[0];
      }
      // let that = this;
      // that.cardAccessGroupList = that.paginate(that.cardAccessGroupListTemp, 5, value);
    },
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.classBgRed {
  background-color: rgba(92, 184, 92, 0.3);
}
.classBgWhite {
  background-color: white;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
