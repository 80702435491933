import { render, staticRenderFns } from "./ManageRole.vue?vue&type=template&id=ad14cd64&scoped=true"
import script from "./ManageRole.vue?vue&type=script&lang=js"
export * from "./ManageRole.vue?vue&type=script&lang=js"
import style0 from "./ManageRole.vue?vue&type=style&index=0&id=ad14cd64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad14cd64",
  null
  
)

export default component.exports