<template>
  <v-container
    style="
      max-width: 1700px;
      max-height: 750px;
      padding-top: 3%;
      padding-bottom: 3%;
    "
  >
    <v-row style="display: flex; justify-content: center">
      <iframe width="1700" height="750" :src="link" style="background-color: white;"></iframe>
    </v-row>
    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import enurl from "@/api/environment";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "Reportss",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      reportImg: require("@/assets/access.jpg"),
      reportMenu: [],
      MenuPermission: [],
      cards: [],
      cardsData: [],
      link: "http://10.144.52.130/ReportMenu.aspx",
      link2: "https://v2.vuetifyjs.com/en/components/buttons/#raised",
    };
  },
  async mounted() {
    let that = this;
    that.getDataMenuPermission();
  },
  computed: {},
  watch: {},
  methods: {
    getDataMenuPermission() {
      let that = this;
      axios
        .get(`${that.url}Permission/getDataMenuPermission`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.cardsData = response.data.data;
            console.log(that.cardsData);

            if (
              that.cardsData.indexOf(
                that.cardsData.find((x) => x.groupID == 5)
              ) != -1
            ) {
              var Index = that.cardsData.indexOf(
                that.cardsData.find((x) => x.groupID == 5)
              );
              that.reportMenu.push(that.cardsData.find((x) => x.groupID == 5));
              that.cardsData.splice(Index, 1);
            }

            that.cards = that.cardsData;
            that.cards.forEach((element) => {
              if (element.groupID == 1) {
                element.src = require("@/assets/access.jpg");
                element.flex = 3;
              }
              if (element.groupID == 2) {
                element.src = require("@/assets/carpark.jpg");
                element.flex = 3;
              }
              if (element.groupID == 3) {
                element.src = require("@/assets/VisitorManagementBG.jpg");
                element.flex = 3;
              }
              if (element.groupID == 4) {
                element.src = require("@/assets/settings.jpg");
                element.flex = 3;
              }
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
          eventBus.$emit("logout");
        });
    },
    routerPage(page) {
      let that = this;
      localStorage.setItem("FirstPage", "true");
      if (page == "Access Control") {
        localStorage.setItem("GroupId", 1);
        that.$router.push("/Dashboard");
        localStorage.setItem("page", "Dashboard");
      } else if (page == "Carpark Management") {
        localStorage.setItem("GroupId", 2);
        that.$router.push("/DashboardCarpark");
        localStorage.setItem("page", "Dashboard Carpark");
      } else if (page == "Visitor Management") {
        localStorage.setItem("GroupId", 3);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      } else if (page == "Setting") {
        localStorage.setItem("GroupId", 4);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      }
    },
  },
};
</script>
<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}
.TitleCSS {
  font-size: 18px;
  font-weight: 300;
  color: black;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: local("English"), url(../assets/Font/Kanit-Regular.ttf);
}
* >>> .v-data-table-header {
  background-color: #333333 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
