import font_kit from "fontkit";
import { PDFDocument, rgb, degrees } from "pdf-lib";

export async function generatePDF(data, mode) {

  // Initial variable
  const PDF_TEMPLATE = "/assets/E_Tax_Invoice_Template.pdf";
  const THAI_FONT = "/assets/TH Sarabun New Bold.ttf";
  const FONT_SIZE = 14;
  const FONT_COLOR = rgb(0, 0, 0);

  const existingPdfBytes = await fetch(PDF_TEMPLATE).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const page = pdfDoc.getPage(0);
  const { width, height } = page.getSize();

  pdfDoc.registerFontkit(font_kit);
  const thaiFontBytes = await fetch(THAI_FONT).then(res => res.arrayBuffer());
  const thaiFont = await pdfDoc.embedFont(thaiFontBytes);

  const textList = [
    { data: data.tax_invoice_no, x: 199, y: height - 156, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.pos_no, x: 106, y: height - 173, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.name, x: 92, y: height - 191, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.address, x: 88, y: height - 208, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.tax_id, x: 153, y: height - 226, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },

    { data: data.print_id, x: 445, y: height - 261, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.print_date, x: 445, y: height - 279, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_date, x: 445, y: height - 297, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },

    { data: data.paid_item.no, x: 82, y: height - 360, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_item.list, x: 125, y: height - 360, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_item.amount, x: 310, y: height - 360, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_item.vat, x: 400, y: height - 360, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_item.total, x: 470, y: height - 360, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_total, x: 470, y: height - 424, size: FONT_SIZE + 2, font: thaiFont, color: FONT_COLOR },
    { data: data.paid_total_thai, x: 200, y: height - 424, size: FONT_SIZE, font: thaiFont, color: FONT_COLOR },
  ];

  // Write watermark on "Preview mode" (mode = false)
  if (mode == false) {
    page.drawText("ตัวอย่าง", {
      x: width / 4.5,
      y: height / 2,
      size: 200,
      font: thaiFont,
      color: rgb(0.5, 0.5, 0.5),
      rotate: degrees(25),
      opacity: 0.5,
    });
  }

  for (const item of textList) {
    if (item.data != null && item.data != "") {
      page.drawText(item.data, {
        x: item.x,
        y: item.y,
        size: item.size,
        font: item.font,
        color: item.color,
      });
    }
  }

  return pdfDoc;
}
