<template>
  <nav>
    <v-toolbar color="#1D2939" height="80px" style="background-color: #1d2939">
      <div class="text-center">
        <v-img
          src="@/assets/LogoFinedayFullname.png"
          width="250"
          height="70"
          style="margin-left: -0.5rem; cursor: pointer"
          @click="mainPage()"
        />
      </div>
      <v-spacer
        style="text-align: -webkit-right; padding-right: 20px; color: white"
      >
        <v-icon
          v-if="urlImg == null"
          class="mr-3"
          size="33"
          style="color: white"
          >mdi-account-circle</v-icon
        >

        <v-img
          v-if="urlImg != null"
          class="bg-white"
          width="50"
          style="border: 1px solid #ffffff; border-radius: 25px"
          :aspect-ratio="1"
          :src="urlImg"
        ></v-img>
      </v-spacer>
      <label style="color: white; margin-right: 20px">{{ fullname }}</label>

      <template>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Dropdown
              </v-btn> -->
              <v-icon v-bind="attrs" v-on="on" style="color: white"
                >mdi-arrow-down-drop-circle-outline</v-icon
              >
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                link
                @click="OptionLog(item.action)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-toolbar>

    <v-dialog v-model="dialogChangePassword" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      outlined
                      v-model="oldPassword"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Old Password"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      outlined
                      v-model="newPassword"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      name="input-10-1"
                      label="New Password"
                      counter
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      outlined
                      v-model="reNewPassword"
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show3 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Renew Password"
                      counter
                      @click:append="show3 = !show3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="repassword()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialogChangePassword = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChangeProfile" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Change Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        style="text-align: -webkit-center"
                      >
                        <v-img
                          class="bg-white"
                          width="150"
                          style="border: 1px solid #000000"
                          :aspect-ratio="1"
                          :src="urlImg"
                          cover
                        ></v-img>
                        <v-file-input
                          label="Image"
                          accept="image/png, image/jpeg, image/bmp"
                          append-icon="mdi-camera"
                          v-model="image"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            outlined
                            label="First Name*"
                            v-model="firstname"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            outlined
                            label="Last Name*"
                            v-model="lastname"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      label="Department"
                      append-icon="mdi-card-account-details-outline"
                      v-model="department"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      label="Position"
                      append-icon="mdi-card-account-details-outline"
                      v-model="position"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      outlined
                      label="Note"
                      append-icon="mdi-note-text-outline"
                      v-model="note"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="changeProfile()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialogChangeProfile = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </nav>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";
export default {
  data() {
    return {
      urlImg: null,
      loading4: false,
      offset: true,
      url: enurl.apiUrl,
      PositionID: null,
      LoadingDialog: false,
      dialogChangePassword: false,
      dialogChangeProfile: false,
      show: false,
      username: localStorage.getItem("Username"),
      fullname: this.$cookies.get("FullName"),
      drawer: true,
      menuBar: "",
      oldPassword: "",
      userID: 0,
      newPassword: "",
      reNewPassword: "",
      show1: false,
      show2: false,
      show3: false,
      images: {
        logo: require("@/assets/3F3I.gif"),
      },
      // NavbarList: [],
      NavbarListCamera: [
        {
          appName: "Camera",
          icon: "mdi-home",
        },
      ],

      personID: "",
      firstname: "",
      lastname: "",
      department: "",
      position: "",
      note: "",

      userName: "",
      password: "",
      role: "",
      cardsData: [],
      selectedItem: 0,
      image: null,
      items: [
        { title: "Change Profile", action: "Change Profile" },
        { title: "Change Password", action: "Change Password" },
        { title: "Logout", action: "Logout" },
      ],
    };
  },

  created() {
    eventBus.$on("logout", () => {
      this.Logout();
      Swal.fire("You have lost your session!");
    });
  },

  mounted() {
    this.getProfile();
    // this.getDataMenuPermission();
    // this.getDataNavbarPermission();
  },

  watch: {
    image(newVal) {
      if (newVal) {
        this.createBase64Image();
      } else {
        this.base64 = "";
        this.urlImg = null;
      }
    },
  },

  methods: {
    createBase64Image() {
      let self = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.name = this.image.name; //get the image's name
      reader.size = this.image.size;
      reader.onload = (event) => {
        var img = new Image();
        img.src = event.target.result;
        img.name = event.target.name;
        img.size = event.target.size;
        img.onload = function (el) {
          var elem = document.createElement("canvas");
          var scaleFactor = 400 / el.target.width;
          elem.width = 400;
          elem.height = el.target.height * scaleFactor;

          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          var srcEncoded = ctx.canvas.toDataURL("image/png", 1);
          self.base64 = srcEncoded;
          // self.urlImg = self.base64;
          // console.log(srcEncoded);
          self.UploadFiles();
        };
      };
    },
    UploadFiles() {
      let self = this;

      let temp = {
        filesName: self.image.name,
        base64: self.base64,
      };
      // console.log(temp);
      axios
        .post(`${self.url}Member/UploadFiles`, temp)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status == 0) {
            let dataImage = {
              name: response.data.data.imageName,
              base64: response.data.data.imageResponse,
              isDefault: true,
            };

            self.urlImg = dataImage.base64;
            self.base64 = dataImage.base64;
          }
          if (response.data.status == 1) {
            // self.urlImg = self.urlImg ;
            // self.image = null ;
            Swal.fire({
              icon: "warning",
              title: "รูปนี้ไม่เหมาะสมในการนำมาลงทะเบียน",
              showConfirmButton: true,
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    repassword() {
      let that = this;
      if (that.newPassword != that.reNewPassword) {
        Swal.fire({
          icon: "warning",
          title: "New Passwords do not match ",
          showConfirmButton: true,
          timer: 1500,
        });
        return;
      }
      let temp = {
        password: that.newPassword,
        oldPassword: that.oldPassword,
      };
      axios
        .post(`${that.url}Login/ChangePassword`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            that.dialogChangePassword = false;
            that.Logout();
          }
          if (response.data.status == 2) {
            that.Logout();
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          that.dialogChangePassword = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    mapEdit(data) {
      let that = this;
      that.firstname = data.firstName;
      that.lastname = data.lastName;
      that.department = data.department;
      that.position = data.position;
      that.note = data.note;
      that.userID = data.user_ID;

      that.role = data.role_ID;
      that.userName = data.user_Name;
      that.password = data.user_PWD;
      that.urlImg = data.profileImage;
      that.base64 = data.profileImage;

      const d = new Date();
      d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); //7days
      let expires = "expires=" + d.toUTCString();

      document.cookie =
        "FullName" +
        "=" +
        that.firstname +
        " " +
        that.lastname +
        ";" +
        expires +
        ";path=/";
      that.fullname = that.$cookies.get("FullName");
    },
    OptionLog(option) {
      let that = this;
      if (option == "Logout") {
        that.Logout();
      } else if (option == "Change Profile") {
        that.getProfile();
        that.dialogChangeProfile = true;
      } else if (option == "Change Password") {
        that.dialogChangePassword = true;
        that.oldPassword = "";
        that.newPassword = "";
        that.reNewPassword = "";
      } else if (option == "Setting") {
        localStorage.setItem("FirstPage", "true");
        localStorage.setItem("GroupId", 4);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      }
    },

    // getDataMenuPermission() {
    //   let that = this;
    //   that.LoadingDialog = true;
    //   axios
    //     .get(`${that.url}Permission/getDataMenuPermission`)
    //     .then(function (response) {
    //       if (response.data.status == 0) {
    //         that.cardsData = response.data.data;

    //         if (
    //           that.cardsData.indexOf(
    //             that.cardsData.find((x) => x.groupID == 4)
    //           ) != -1
    //         ) {
    //           var temp = { title: "Setting", action: "Setting" };

    //           that.items.push(temp);

    //           // var Index0 = that.cardsData.indexOf(
    //           //   that.cardsData.find((x) => x.groupID == 4)
    //           // );
    //           // that.reportMenu.push(that.cardsData.find((x) => x.groupID == 4));
    //           // that.cardsData.splice(Index0, 1);
    //         }
    //       }
    //       if (response.data.status == 2) {
    //         eventBus.$emit("logout");
    //       }
    //       that.LoadingDialog = false;
    //     })
    //     .catch(function (error) {
    //       that.LoadingDialog = false;
    //       if (error.response.status != 404) {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Error...",
    //           width: 900,
    //           text: error.response.data.message,
    //         });
    //       }
    //       eventBus.$emit("logout");
    //     });
    // },

    changeProfile() {
      let that = this;
      that.LoadingDialog = true;

      let temp = {
        User_ID: that.userID,
        FirstName: that.firstname,
        LastName: that.lastname,
        User_Name: that.userName,
        User_PWD: that.password,
        Department: that.department,
        Position: that.position,
        RoleID: that.role,
        note: that.note,
        ProfileImage: that.base64,
        active: true,
      };
      axios
        .post(`${that.url}User/editProfile`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            that.getProfile();
          }
          if (response.data.status == 2) {
            that.Logout();
          }
          that.dialogChangeProfile = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    // getMenuActive() {
    //   let that = this;
    //   let page = localStorage.getItem("page");
    //   if (page != null) {
    //     that.selectedItem = this.NavbarList.findIndex((x) => x.appName == page);
    //   }
    // },
    mainPage() {
      let that = this;
      that.$router.push("/Main");
    },
    getProfile() {
      let that = this;
      axios
        .get(`${that.url}Login/getProfile`)
        .then(function (response) {
          if (response.data.status == 0) {
            // console.log(response.data);
            that.userID = response.data.data.user_ID;
            // that.oldPassword = response.data.data.user_PWD;
            that.mapEdit(response.data.data);
          }
          if (response.data.status == 2) {
            that.Logout();
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    // getDataNavbarPermission() {
    //   let that = this;
    //   let temp = {
    //     groupID:
    //       localStorage.getItem("GroupId") == undefined
    //         ? "1"
    //         : localStorage.getItem("GroupId"),
    //   };
    //   axios
    //     .post(`${that.url}Permission/getDataNavbarPermissionByGroupID`, temp)
    //     .then(function (response) {
    //       if (response.data.status == 0) {
    //         that.NavbarList = response.data.data;
    //         // let data = {
    //         //   appName: "Camera",
    //         //   icon: "mdi-home",
    //         // };
    //         // that.NavbarList.push(data);
    //         that.getMenuActive();
    //       }
    //       if (response.data.status == 2) {
    //         that.Logout();
    //       }
    //     })
    //     .catch(function (error) {
    //       that.LoadingDialog = false;
    //       if (error.response.status != 404) {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Error...",
    //           width: 900,
    //           text: error.response.data.message,
    //         });
    //       }
    //     });
    // },

    // NavigatePage(page) {
    //   let that = this;
    //   localStorage.setItem("page", page);
    //   if (page == "Member") {
    //     that.$router.push("/ManageMember");
    //   } else if (page == "Tenant") {
    //     that.$router.push("/Tenant");
    //   } else if (page == "Transaction Report") {
    //     that.$router.push("/TransactionReport");
    //   } else if (page == "Visitor Report") {
    //     that.$router.push("/VisitorReport");
    //   } else if (page == "Carpark Report") {
    //     that.$router.push("/CarparkReport");
    //   } else if (page == "Member Exist Button") {
    //     that.$router.push("/MemberExistButton");
    //   } else if (page == "Setting Button") {
    //     that.$router.push("/SettingButton");
    //   } else if (page == "User Management") {
    //     that.$router.push("/ManageUser");
    //   } else if (page == "Carpark Management") {
    //     that.$router.push("/CarparkManagement");
    //   } else if (page == "Vms Search") {
    //     that.$router.push("/VmsSearch");
    //   } else if (page == "Redemption Management") {
    //     that.$router.push("/RedemptionManagement");
    //   } else if (page == "Camera") {
    //     that.$router.push("/Camera");
    //   } else if (page == "Dashboard") {
    //     that.$router.push("/Dashboard");
    //   } else if (page == "Role Management") {
    //     that.$router.push("/ManageRole");
    //   } else if (page == "Tower Management") {
    //     that.$router.push("/ManageTower");
    //   } else if (page == "Zone Management") {
    //     that.$router.push("/ManageZone");
    //   } else {
    //     that.$router.push("/Beacons");
    //   }
    // },

    // CameraPage() {
    //   let that = this;
    //   that.$router.push("/Camera");
    // },

    // getIcon(data) {
    //   let iconName = "";
    //   if (data == "Member") {
    //     iconName = "mdi-account-multiple";
    //   } else if (data == "Tenant") {
    //     iconName = "mdi-briefcase-account";
    //   } else if (data == "Transaction Report") {
    //     iconName = "mdi-chart-bar";
    //   } else if (data == "Visitor Report") {
    //     iconName = "mdi-chart-line";
    //   } else if (data == "Carpark Report") {
    //     iconName = "mdi-chart-pie";
    //   } else if (data == "Member Exist Button") {
    //     iconName = "mdi-account-convert";
    //   } else if (data == "Setting Button") {
    //     iconName = "mdi-cellphone-cog";
    //   } else if (data == "User Management") {
    //     iconName = "mdi-account-cog";
    //   } else if (data == "Carpark Management") {
    //     iconName = "mdi-car-cog";
    //   } else if (data == "Vms Search") {
    //     iconName = "mdi-account-key-outline";
    //   } else if (data == "Redemption Management") {
    //     iconName = "mdi-account-tie";
    //   } else if (data == "Tower Management") {
    //     iconName = "mdi-office-building";
    //   } else if (data == "Beacon Management") {
    //     iconName = "mdi-signal";
    //   } else if (data == "Zone Management") {
    //     iconName = "mdi-cog";
    //   } else if (data == "Role Management") {
    //     iconName = "mdi-account-lock-outline";
    //   } else if (data == "Zone Management") {
    //     iconName = "mdi-map-marker-radius-outline";
    //   } else {
    //     iconName = "mdi-cog";
    //   }
    //   return iconName;
    // },

    Logout() {
      let that = this;
      let temp = {
        // token: localStorage.getItem("Token"),
        token: this.$cookies.get("Token"),
      };
      axios
        .post(`${that.url}Login/Logout`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.$cookies.remove("Token");
            that.$cookies.remove("isLoggedIn");
            that.$cookies.remove("FullName");
            that.$store.dispatch("Logout").then(() => {
              localStorage.clear();
              that.$router.push("/Login");
            });
          }
          if (response.data.status == 1) {
            that.Logout();
          }
          if (response.data.status == 2) {
            that.Logout();
          }
        })
        .catch(function () {
          that.LoadingDialog = false;

          that.$cookies.remove("Token");
          that.$cookies.remove("isLoggedIn");
          that.$cookies.remove("FullName");
          that.$store.dispatch("Logout").then(() => {
            localStorage.clear();
            that.$router.push("/Login");
          });
        });
    },
  },
};
</script>
<style>
.app {
  margin-left: 1rem;
  cursor: pointer;
}
.list {
  cursor: pointer;
}
.apptitle {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.v-list-item__content {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.mdi-menu-down::before {
  color: #9ac5f4;
}
.white-color {
  background-color: red;
  color: red;
}
.item-title {
  font-size: 16px;
  font-weight: 500;
}
.v-application--is-ltr
  .v-list-group--no-action.v-list-group--sub-group
  .v-list-group__items
  .v-list-item {
  padding-left: 20px !important;
}
.NavbarCSS:hover {
  background-color: #c9e9f6;
}
.border {
  background: rgb(249, 249, 249);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  text-decoration: none;
}

.border b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 93%;
  background: #fff;

  display: none;
}
.border b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  background: #22577a;
}

.border b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 93%;
  background: #fff;
  display: none;
}
.border b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  background: #22577a;
}
.border b:nth-child(1),
.border b:nth-child(2) {
  display: block;
}

@keyframes clouds-loop-1 {
  to {
    background-position: -1000px 0;
  }
}
.clouds-1 {
  background-image: url("https://cdn.discordapp.com/attachments/990641651175264306/1026619079949291570/clouds_233.png");
  animation: clouds-loop-1 20s infinite linear;
}

@keyframes clouds-loop-2 {
  to {
    background-position: -1000px 0;
  }
}
.clouds-2 {
  background-image: url("https://cdn.discordapp.com/attachments/990641651175264306/1026617444720201728/clouds_991.png");
  animation: clouds-loop-2 15s infinite linear;
}

@keyframes clouds-loop-3 {
  to {
    background-position: -1579px 0;
  }
}
.clouds-3 {
  background-image: url("https://cdn.discordapp.com/attachments/990641651175264306/1026615143339147374/clouds_55553.png");
  animation: clouds-loop-3 17s infinite linear;
}
.clouds {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.clouds-1,
.clouds-2,
.clouds-3 {
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 500px;
}
</style>
