<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Mifare Card Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 1%; margin-top: 1%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start_date"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="end_date"
                        label="Expire Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="7" lg="7" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="searchData"
                    v-on:keyup.enter="SearchCard(searchData)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-select
                    :items="['Expire', 'Not Expire']"
                    label="Expire status"
                    v-model="expire_status"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-select
                    :items="['Use', 'Not Use']"
                    label="Use status"
                    v-model="use_status"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-select
                    :items="['Active', 'Inactive']"
                    label="Active status"
                    v-model="active_status"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getDataCardMifareAccessControlList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenCardDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 1%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="userID == 1 ? headersAdmin : headers"
                :items="mifareCardList"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
                hide-default-footer
                style="color: #332f2fde"
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.cardNumber }}</td>
                    <td class="header-table-css">{{ item.owner }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.startDate) }}
                    </td>

                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.expiredDate) }}
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.expire == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.use == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td class="header-table-css" v-if="userID == 1">
                      {{ ChangeFormatDateTime(item.updateTime) }}
                    </td>
                    <td class="header-table-css" v-if="userID == 1">
                      {{ item.updateByName }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 90px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenCardDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveCardByCardID(item.id)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="CardDialog" persistent max-width="900px" scrollable>
      <v-card>
        <v-card-title
          style="background-color: black; color: white; padding: 5px"
        >
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span v-if="EditStatus" class="text-h5">Edit Mifare Card</span>
            <span v-else class="text-h5">Create Mifare Card</span>
          </v-col>
          <v-col cols="6" sm="6" md="6" style="text-align: end">
            <v-tooltip bottom color="white" v-if="EditStatus">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" v-bind="attrs" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="font-size: 15px; color: black"
                >
                  Create By : {{ createBy }} &nbsp;
                  {{ ChangeFormatDateTime(createTime) }}<br />
                  Update By : {{ updateBy }} &nbsp;
                  {{ ChangeFormatDateTime(updateTime) }}<br />
                </v-col>
              </v-row>
            </v-tooltip>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Card Number*"
                      v-model="cardNumber"
                      type="text"
                      :disabled="EditStatus"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Card UID*"
                      v-model="cardUID"
                      type="text"
                      :disabled="EditStatus"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuStartDateCard"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateCard"
                          label="Start Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDateCard"
                        @change="menuStartDateCard = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuExpireDateCard"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="expireDateCard"
                          label="Expire Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="expireDateCard"
                        @change="menuExpireDateCard = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      :items="Member_Type"
                      clearable
                      v-model="memberType"
                      item-text="type_name"
                      item-value="id"
                      label="Member Type *"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Remark" v-model="remark" required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="2" md="3">
                    <v-checkbox
                      v-model="blacklistStatus"
                      :label="`Blacklist Status`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="2" md="3">
                    <v-checkbox
                      v-model="lostStatus"
                      :label="`Lost Status`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="1" md="3">
                    <v-switch
                      v-model="active"
                      hide-details
                      inset
                      :label="active ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <p
                  style="color: #444444; font-size: 18px; font-weight: regular"
                >
                  Access Groups
                </p>
                <v-row>
                  <v-autocomplete
                    ref="inputCardNumber"
                    v-model="SearchDataAccessGroup"
                    :items="getCardsAccessGroup"
                    item-text="tagrP_Name"
                    item-value="accessID"
                    color="#126496"
                    prepend-inner-icon="mdi-magnify"
                    cols="12"
                    sm="6"
                    md="3"
                    outlined
                    return-object
                    dense
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-row style="height: 50px; margin-top: -0.5rem">
                            <v-col>
                              <v-list-item-title
                                v-html="data.item.tagrP_Name"
                              ></v-list-item-title>
                            </v-col>
                            <v-col class="text-end">
                              <v-btn
                                text
                                style="
                                  background-color: #126496;
                                  color: #ffffff;
                                  text-transform: none !important;
                                  margin-top: -0.65rem;
                                "
                                @click="AddDataDropdown(data.item)"
                                >Add</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-data-table
                  :headers="headersDropdown"
                  :items="cardAccessGroupList"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.no="{ item }">
                    {{ cardAccessGroupList.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn text @click="DeleteDataDropdown(item)">
                      <img src="@/assets/close.png" width="15" height="15" />
                      <span style="color: #ff4839" class="ml-1 text-capitalize"
                        >Delete</span
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addMifareCard()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addMifareCard()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CardDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "MifareCardAccess",
  components: {},
  data() {
    // const defaultSearchData = Object.freeze({
    //   start_date: "",
    //   end_date: "",
    //   expire_status: null,
    //   text: "",
    //   use_status: null,
    //   active_status: null,
    // });

    return {
      // Initial
      url: enurl.apiUrl,

      // Dialog
      loadingDialog: false,

      // Data table
      menuStartDate: false,
      menuEndDate: false,
      // defaultSearchData,
      searchData: "",

      no_run: null,
      mifareCardList: [],
      userID: null,

      // Page
      permissionsPage: [],
      page: 1,
      pageCount: 0,
      items: [10, 15, 20, 50, 100],
      itemsPerPage: 10,

      start_date: "",
      end_date: "",
      expire_status: null,
      text: "",
      use_status: null,
      active_status: null,

      CardDialog: false,
      EditStatus: false,
      cardNumber: "",
      cardUID: "",
      menuStartDateCard: false,
      menuExpireDateCard: false,
      startDateCard: "",
      expireDateCard: "",
      remark: "",
      blacklistStatus: false,
      lostStatus: false,
      active: false,
      id: 0,
      createBy: "",
      createTime: "",
      updateBy: "",
      updateTime: "",
      Member_Type: [],
      memberType: 0,

      getCardsAccessGroup: [],
      cardAccessGroupList: [],
      cardAccessGroupListTemp: [],
      InputListDataDropdown: null,
      SearchDataAccessGroup: "",
      lengthFloor: 1,
      totalPage: 10,
      headersDropdown: [
        {
          text: "No",
          align: "center",
          value: "no",
        },
        {
          text: "Project Name",
          align: "left",
          value: "projectName",
          sortable: false,
        },
        {
          text: "Tower Name",
          align: "left",
          value: "towerName",
          sortable: false,
        },
        {
          text: "Tag GroupName",
          align: "center",
          value: "tagGroupName",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },
        {
          text: "Start Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Expired Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersAdmin() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },
        {
          text: "Start Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Expired Date",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "LastUpdateTime",
          align: "left",
          sortable: false,
        },
        {
          text: "LastUpdateBy",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    InputListDataDropdown: function (data) {
      let self = this;
      let requestData = {
        page: 1,
        perPage: self.itemsPerPage,
        search: data,
        startDate: self.start_date,
        endDate: self.end_date,
        expire:
          self.expire_status == "Expire"
            ? true
            : self.expire_status == null
            ? null
            : false,
        use:
          self.use_status == "Use"
            ? true
            : self.use_status == null
            ? null
            : false,
        active:
          self.active_status == "Active"
            ? true
            : self.active_status == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardMifareAccessControlList`, requestData)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mifareCardList = response.data.data.listData;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchDataAccessGroup() {
      this.$refs.inputCardNumber.reset();
    },
  },

  mounted() {
    let Permission = localStorage.getItem("Permision");
    if (Permission == null || Permission == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permission).find(
      (x) => x.appName == page
    );

    this.getDataCardMifareAccessControlList();
    this.GetDataCardsAccessGroup();
    this.getMas_Member_Type();
  },

  methods: {
    // Call API
    async getDataCardMifareAccessControlList() {
      let that = this;
      that.loadingDialog = true;
      let requestData = {
        page: that.page,
        perPage: that.itemsPerPage,
        search: that.searchData,
        startDate: that.start_date,
        endDate: that.end_date,
        expire:
          that.expire_status == "Expire"
            ? true
            : that.expire_status == null
            ? null
            : false,
        use:
          that.use_status == "Use"
            ? true
            : that.use_status == null
            ? null
            : false,
        active:
          that.active_status == "Active"
            ? true
            : that.active_status == null
            ? null
            : false,
      };

      await axios
        .post(`${that.url}Card/getDataCardMifareAccessControlList`, requestData)
        .then(function (response) {
          if (response.data.status == 0) {
            that.mifareCardList = response.data.data.listData;
            that.userID = response.data.data.userID;
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchCard(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.page,
        perPage: self.itemsPerPage,
        search: data,
        startDate: self.start_date,
        endDate: self.end_date,
        expire:
          self.expire_status == "Expire"
            ? true
            : self.expire_status == null
            ? null
            : false,
        use:
          self.use_status == "Use"
            ? true
            : self.use_status == null
            ? null
            : false,
        active:
          self.active_status == "Active"
            ? true
            : self.active_status == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardMifareAccessControlList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mifareCardList = response.data.data.listData;
            self.userID = response.data.data.userID;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perPage: self.itemsPerPage,
        search: self.searchData,
        startDate: self.start_date,
        endDate: self.end_date,
        expire:
          self.expire_status == "Expire"
            ? true
            : self.expire_status == null
            ? null
            : false,
        use:
          self.use_status == "Use"
            ? true
            : self.use_status == null
            ? null
            : false,
        active:
          self.active_status == "Active"
            ? true
            : self.active_status == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardMifareAccessControlList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mifareCardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perPage: value,
        search: self.searchData,
        startDate: self.start_date,
        endDate: self.end_date,
        expire:
          self.expire_status == "Expire"
            ? true
            : self.expire_status == null
            ? null
            : false,
        use:
          self.use_status == "Use"
            ? true
            : self.use_status == null
            ? null
            : false,
        active:
          self.active_status == "Active"
            ? true
            : self.active_status == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardMifareAccessControlList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mifareCardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inActiveCardByCardID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        cardID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Card ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Card/inActiveMifareCardAccessByCardID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getDataCardMifareAccessControlList();
                Swal.fire(
                  "Deleted!",
                  "Your card has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    // Common function
    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },

    ChangeFormatDateTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenCardDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        self.cardNumber = "";
        self.cardUID = "";
        self.memberType = "";
        self.startDateCard = "";
        self.expireDateCard = "";
        self.remark = "";
        self.blacklistStatus = false;
        self.lostStatus = false;
        self.active = true;
        self.cardAccessGroupList = [];
        self.CardDialog = true;
      } else {
        self.getDataMifareAccessCardByCardID(data.id);
      }
    },

    addMifareCard() {
      let self = this;
      if (
        self.cardNumber == "" &&
        self.cardUID == "" &&
        self.vehicleTypeID != ""
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน !",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      self.LoadingDialog = true;

      if (!self.EditStatus) {
        let temp = {
          cardUID: self.cardUID,
          cardNumber: self.cardNumber,
          startDate: self.startDateCard == null ? "" : self.startDateCard,
          expiredDate: self.expireDateCard == null ? "" : self.expireDateCard,
          remark: self.remark,
          blacklistStatus: self.blacklistStatus,
          lostStatus: self.lostStatus,
          active: self.active,
          memberType: self.memberType,
          cardAccessGroupList: self.cardAccessGroupList,
        };
        axios
          .post(`${self.url}Card/addMifareCardAccess`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 1) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            self.page = 1;
            self.getDataCardMifareAccessControlList();
            self.CardDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          cardID: self.id,
          cardUID: self.cardUID,
          cardNumber: self.cardNumber,
          startDate: self.startDateCard == null ? "" : self.startDateCard,
          expiredDate: self.expireDateCard == null ? "" : self.expireDateCard,
          remark: self.remark,
          blacklistStatus: self.blacklistStatus,
          lostStatus: self.lostStatus,
          active: self.active,
          memberType: self.memberType,
          cardAccessGroupList: self.cardAccessGroupList,
        };
        axios
          .post(`${self.url}Card/editMifareCardAccess`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 1) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }

            self.getDataCardMifareAccessControlList();
            self.CardDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    getDataMifareAccessCardByCardID(id) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        Id: id,
      };
      axios
        .post(`${self.url}Card/getDataMifareAccessCardByCardID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            self.BindingEditData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.id = data.id;
      self.cardUID = data.cardUID;
      self.cardNumber = data.cardNumber;
      self.memberType = data.memberType;
      self.startDateCard =
        data.startDate == null ? "" : self.ConvertDate(data.startDate);
      self.expireDateCard =
        data.expiredDate == null ? "" : self.ConvertDate(data.expiredDate);
      self.remark = data.remark;
      self.blacklistStatus = data.blacklistStatus;
      self.lostStatus = data.lostStatus;
      self.active = data.active;
      self.createBy = data.createBy;
      self.createTime = data.createTime;
      self.updateBy = data.updateBy;
      self.updateTime = data.updateTime;
      self.cardAccessGroupList = data.cardAccessGroupList;
      self.CardDialog = true;
    },

    ConvertDate(value) {
      if (value != "") {
        return value.split("T")[0];
      }
    },

    GetDataCardsAccessGroup() {
      let self = this;
      self.LoadingDialog = true;
      axios
        .get(`${self.url}Card/getCardsAccessGroup`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.getCardsAccessGroup = response.data.data;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    AddDataDropdown(val) {
      let self = this;
      if (val != null) {
        var CheckDuplicate = this.cardAccessGroupList.find(
          (x) => x.accessID == val.accessID
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            accessID: val.accessID,
            projectID: val.projectID,
            projectName: val.projectName,
            cardNumber: self.cardNumber,
            towerID: val.towerID,
            towerName: val.towerName,
            tagGroupNo: val.tagrP_NO,
            tagGroupName: val.tagrP_Name,
            active: true,
          };
          self.cardAccessGroupList.push(temp);
          self.cardAccessGroupListTemp = self.cardAccessGroupList;
          self.lengthFloor = Math.ceil(self.cardAccessGroupList.length / 5);
        }
      }
    },

    DeleteDataDropdown(item) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.cardAccessGroupList.indexOf(item);
          self.cardAccessGroupList.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    getMas_Member_Type() {
      let self = this;
      axios
        .get(`${self.url}Card/getMas_Member_Type`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.Member_Type = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}

* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}

.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}

.validation-class {
  color: red;
  font-size: 12px;
}

.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}

.rect3 {
  animation-delay: -1.1s;
}

.rect4 {
  animation-delay: -1s;
}

.rect5 {
  animation-delay: -0.9s;
}
</style>
