<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Roles
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="3" class="mt-3">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-on:keyup.enter="SearchMember(search)"
                    v-model="search"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="mt-1" align="center">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="getDataRole()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="green"
                    class="ma-2 white--text"
                    @click="checkDataButton('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="RoleList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">
                      {{ item.roleName }}
                    </td>
                    <td class="header-table-css">
                      {{ item.description }}
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="blue"
                        @click="checkDataButtonPermission(item)"
                      >
                        <v-icon dark> mdi-account-cog </v-icon>
                      </v-btn>
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 140px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="checkDataButton('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveMemberByPersonID(item.personID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>

                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: black; color: white; padding: 5px;">
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span v-if="!editCheck" class="text-h5">Create Role</span>
            <span v-if="editCheck" class="text-h5">Edit Role</span>
          </v-col>
          <v-col cols="6" sm="6" md="6" style="text-align: end">
            <v-tooltip bottom color="white" v-if="editCheck">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <v-row>
                <v-col cols="12" sm="12" md="12" style="font-size: 15px; color: black">
                  Create By : {{ createBy }} &nbsp; {{ ChangeFormatDate(createTime) }}<br />
                  Update By : {{ updateBy }} &nbsp; {{ ChangeFormatDate(updateTime) }}<br />
                </v-col>
              </v-row>
            </v-tooltip>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Role Name*"
                      v-model="roleName"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Description"
                      v-model="description"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10" md="10">
                    <v-select
                      :items="towerList"
                      label="Tower"
                      v-model="tower"
                      item-text="towerName"
                      item-value="towerID"
                      multiple
                      required
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="addRole()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPermission" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5"
            >Update Permission : {{ roleNamePermission }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-tabs class="mt-5" color="deep-purple accent-4" right>
            <v-tab
              v-for="item in dataGroups"
              :key="item"
              @click="selectGroup(item)"
              >{{ item }}</v-tab
            >
            <!-- <v-tab>City</v-tab>
            <v-tab>Abstract</v-tab> -->

            <v-tab-item v-for="n in dataGroups.length" :key="n">
              <v-container fluid>
                <v-row>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        style="overflow-y: scroll; height: 500px"
                      >
                        <template>
                          <v-expansion-panels v-model="panel" focusable>
                            <v-expansion-panel
                              v-for="(item, i) in dataFilter"
                              :key="i"
                            >
                              <v-expansion-panel-header
                                @click="checkboxMenu(item)"
                                >{{ item.appName }}</v-expansion-panel-header
                              >
                              <v-expansion-panel-content>
                                <v-list>
                                  <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-list-item>
                                        <v-checkbox
                                          v-model="checkboxCreate"
                                          @change="calCheckbox(item)"
                                        >
                                        </v-checkbox>
                                        <v-list-item-title
                                          >Create</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-list-item @change="calCheckbox(item)">
                                        <v-checkbox
                                          v-model="checkboxRead"
                                        ></v-checkbox>
                                        <v-list-item-title
                                          >Read</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-list-item @change="calCheckbox(item)">
                                        <v-checkbox
                                          v-model="checkboxUpdate"
                                        ></v-checkbox>
                                        <v-list-item-title
                                          >Update</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-list-item @change="calCheckbox(item)">
                                        <v-checkbox
                                          v-model="checkboxDelete"
                                        ></v-checkbox>
                                        <v-list-item-title
                                          >Delete</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-col>
                                  </v-row>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>

          <!-- <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                style="overflow-y: scroll; height: 500px"
              >
                <template>
                  <v-expansion-panels v-model="panel" focusable>
                    <v-expansion-panel
                      v-for="(item, i) in getAppResourceArray"
                      :key="i"
                    >
                      <v-expansion-panel-header @click="checkboxMenu(item)">{{
                        item.appName
                      }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list>
                          <v-row>
                            <v-col cols="12" sm="3" md="3">
                              <v-list-item>
                                <v-checkbox
                                  v-model="checkboxCreate"
                                  @change="calCheckbox(item)"
                                >
                                </v-checkbox>
                                <v-list-item-title>Create</v-list-item-title>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                              <v-list-item @change="calCheckbox(item)">
                                <v-checkbox v-model="checkboxRead"></v-checkbox>
                                <v-list-item-title>Read</v-list-item-title>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                              <v-list-item @change="calCheckbox(item)">
                                <v-checkbox
                                  v-model="checkboxUpdate"
                                ></v-checkbox>
                                <v-list-item-title>Update</v-list-item-title>
                              </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                              <v-list-item @change="calCheckbox(item)">
                                <v-checkbox
                                  v-model="checkboxDelete"
                                ></v-checkbox>
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-col>
            </v-row>
          </v-container> -->
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="addPermission()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialogPermission = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "ManageMember",
  components: {
    AlertComponent,
  },
  data() {
    return {
      fav: true,
      menu: 0,
      message: false,
      checkboxCreate: false,
      checkboxRead: false,
      checkboxUpdate: false,
      checkboxDelete: false,
      panel: [0],
      checkbox1: true,
      expand: false,
      model: null,
      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      dialogPermission: false,
      pageCount: 0,
      image: null,
      items: [10, 15, 20, 50, 100],
      page: 1,
      itemsPerPage: 10,
      search: "",
      role: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      addData: false,
      Switch: false,
      urlImg: null,
      RoleList: [],
      tenant: [],
      itemsAction: [{ title: "Edit" }, { title: "Delete" }],
      roleIDNum: 0,
      roleID: "",
      roleName: "",
      roleNamePermission: "",
      description: "",
      roleArray: [],
      getAppResourceArray: [],
      listPermission: [],
      startDate: "",
      endDate: "",
      editCheck: false,
      activeSearch: null,
      permissionCode: 0,
      permissionsPage: [],
      dataGroups: [],
      Groups: "Access Control",
      permissionEnum: [
        { Description: "None", Code: 0 },
        { Description: "Create", Code: 1 },
        { Description: "Read", Code: 2 },
        { Description: "Update", Code: 4 },
        { Description: "Delete", Code: 8 },
        { Description: "ALL", Code: 15 },
      ],
      tower: [],
      towerList: [],
      createBy: "",
      createTime: "",
      updateBy: "",
      updateTime: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "RoleName",
          align: "center",
          sortable: false,
        },
        {
          text: "Description",
          align: "center",
          sortable: false,
        },
        {
          text: "Permission",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },

    dataFilter() {
      return this.getAppResourceArray.filter((x) => x.groupName == this.Groups);
    },
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  mounted() {
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    this.getDataRole();
    this.getRole();
    this.getTowerList();
  },
  methods: {
    selectGroup(item) {
      this.Groups = item;
      this.panel = [0];
      // console.log(item);
    },
    calCheckbox(data) {
      let that = this;

      let Create = 0;
      let Read = 0;
      let Update = 0;
      let Delete = 0;
      if (that.checkboxCreate) {
        Create = 1;
      }
      if (that.checkboxRead) {
        Read = 2;
      }
      if (that.checkboxUpdate) {
        Update = 4;
      }
      if (that.checkboxDelete) {
        Delete = 8;
      }
      data.permissionCode = Create | Read | Update | Delete;
    },
    checkboxMenu(data) {
      let that = this;

      that.checkboxCreate = false;
      that.checkboxRead = false;
      that.checkboxUpdate = false;
      that.checkboxDelete = false;
      if ((data.permissionCode & 1) == 1) {
        that.checkboxCreate = true;
      }
      if ((data.permissionCode & 2) == 2) {
        that.checkboxRead = true;
      }
      if ((data.permissionCode & 4) == 4) {
        that.checkboxUpdate = true;
      }
      if ((data.permissionCode & 8) == 8) {
        that.checkboxDelete = true;
      }
    },
    checkDataButtonPermission(data) {
      let that = this;

      that.roleNamePermission = data.roleName;
      that.panel = [0];
      // that.dialogPermission = true;
      that.roleIDNum = data.roleID;
      this.getAppResource();
    },

    getDataPermission() {
      let that = this;
      let temp = {
        RoleID: that.roleIDNum,
      };
      axios
        .post(`${that.url}Role/getRolePermissionByRoleID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            let dataPermission = response.data.data[0].permissionList;

            for (
              let index = 0;
              index < that.getAppResourceArray.length;
              index++
            ) {
              if (
                dataPermission.find(
                  (x) => x.appID == that.getAppResourceArray[index].appID
                ) != null
              ) {
                that.getAppResourceArray[index].permissionCode =
                  dataPermission.find(
                    (x) => x.appID == that.getAppResourceArray[index].appID
                  ).permissionCode;
              } else {
                that.getAppResourceArray[index].permissionCode = 0;
              }
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      that.dialogPermission = true;
    },
    getAppResource() {
      let that = this;

      axios
        .get(`${that.url}Role/getAppResource`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getAppResourceArray = response.data.data;
            that.dataGroups = [
              ...new Set(that.getAppResourceArray.map((x) => x.groupName)),
            ];
            // console.log(that.dataGroups);

            for (
              let index = 0;
              index < that.getAppResourceArray.length;
              index++
            ) {
              that.getAppResourceArray[index].permissionCode = 0;
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.getDataPermission();
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getTowerList() {
      let self = this;
      axios
        .get(`${self.url}Tower/getDropdownTower`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.towerList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    checkDataButton(type, data) {
      let that = this;
      if (type == "add") {
        that.editCheck = false;
        that.dialog = true;
        // that.Switch = true;
        that.roleName = "";
        that.description = "";
        that.tower = [];
      } else {
        that.editCheck = true;
        that.dialog = true;
        // that.Switch = true;
        that.roleID = data.roleID;
        that.roleName = data.roleName;
        that.description = data.description;
        that.tower = data.towerList;
        that.createBy = data.createBy;
        that.createTime = data.createTime;
        that.updateBy = data.updateBy;
        that.updateTime = data.updateTime;
      }
    },

    mapEdit(data) {
      let that = this;
      that.editCheck = true;
      that.dialog = true;
      that.firstname = data.firstName;
      that.lastname = data.lastName;

      that.userName = data.user_Name;
      that.password = data.user_PWD;
      that.department = data.department;
      that.position = data.position;
      that.note = data.note;
      that.user_ID = data.user_ID;
      that.role = data.role_ID;
      
      that.Switch = data.active == true ? data.active : false;
    },
    checkVakidates() {
      let that = this;
      if (that.roleName == "" || that.description == "") {
        return true;
      } else {
        return false;
      }
    },

    TogglePassword() {
      let that = this;
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        that.flagPassword = false;
      } else {
        x.type = "password";
        that.flagPassword = true;
      }
    },

    getDataRole() {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: that.search == null ? "" : that.search,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Role/getRolePermissionList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.RoleList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchMember(data) {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: data,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Role/getRolePermissionList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.RoleList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getUserByUserID(id) {
      let that = this;
      let temp = {
        User_ID: id,
      };
      axios
        .post(`${that.url}User/getUserByUserID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.mapEdit(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inActiveMemberByPersonID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        roleID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Role ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Role/inActiveRoleByRoleID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getDataRole();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    addRole() {
      let that = this;
      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      that.LoadingDialog = true;

      if (!that.editCheck) {
        let temp = {
          roleName: that.roleName,
          description: that.description,
          tower: that.tower
        };
        axios
          .post(`${that.url}Role/addRole`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getDataRole();
            that.dialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          roleID: that.roleID,
          roleName: that.roleName,
          description: that.description,
          tower: that.tower
        };
        axios
          .post(`${that.url}Role/editRole`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getDataRole();
            that.dialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    addPermission() {
      let that = this;
      that.LoadingDialog = true;
      that.listPermission = [];
      // that.roleIDNum
      that.getAppResourceArray.forEach((a) => {
        let dataTemp = {
          roleID: that.roleIDNum,
          appID: a.appID,
          permissionCode: a.permissionCode,
        };
        that.listPermission.push(dataTemp);
      });
      let temp = {
        listPermission: that.listPermission,
      };
      axios
        .post(`${that.url}Role/updatePermission`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.getDataRole();
          window.location.reload();
          that.dialogPermission = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImg = URL.createObjectURL(file);
    },
    getImage() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    },
    ChangePage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = value;
      let temp = {
        page: value,
        perpage: that.itemsPerPage,
        search: that.search == null ? "" : that.search,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Role/getRolePermissionList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.RoleList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: that.search == null ? "" : that.search,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Role/getRolePermissionList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.RoleList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    getRole() {
      let that = this;
      axios
        .get(`${that.url}User/getRole`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.roleArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}

* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}

.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}

.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}

.validation-class {
  color: red;
  font-size: 12px;
}

.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}

.rect3 {
  animation-delay: -1.1s;
}

.rect4 {
  animation-delay: -1s;
}

.rect5 {
  animation-delay: -0.9s;
}
</style>
