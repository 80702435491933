<template>
  <div style="background-color: #f9f9f9; min-height: 85vh">
    <!-- Main section -->
    <div>
      <div style="text-align: left; margin-left: 26px; margin-right: 26px">
        <div class="pt-4 ma-3" style="font-size: 20px; color: #444444">
          <v-icon large left> mdi-weight-kilogram </v-icon>
          <span>Weight</span>

          <v-card class="v-card-panel" elevation="1">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  <span
                    class="mdi mdi-home"
                    style="cursor: pointer; font-size: 30px"
                    @click="backhome()"
                  ></span>
                  <span v-if="title != ''" style="cursor: pointer">
                    <v-icon>mdi-forward</v-icon> {{ title }}</span
                  >
                </v-list-item-title>
                <hr />

                <span
                  style="
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                  "
                  @click="report()"
                >
                  <v-btn depressed color="primary"> Report </v-btn></span
                >
                <div v-if="title == ''" style="margin: 20px">
                  {{ title == "" ? "เครื่องชั่งน้ำหนัก" : title }}
                </div>

                <v-row style="padding: 15px" v-if="home == '1'">
                  <v-col
                    v-for="(item, index) in items"
                    :key="index"
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-card
                      height="150"
                      elevation="5"
                      style="cursor: pointer"
                      @click="getDataWeighing(item)"
                    >
                      <v-card-title>{{ item.title }}</v-card-title>
                      <v-card-subtitle>{{ "จุดที่ตั้ง" }}</v-card-subtitle>
                      <v-card-text>{{ item.description }}</v-card-text>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" sm="2" md="2">
                    <v-card
                      height="150"
                      elevation="5"
                      style="
                        cursor: pointer;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                      "
                    >
                      <v-card-subtitle
                        style="
                          font-size: 50px;
                          font-weight: bold;
                          font-weight: bold;
                        "
                        ><span class="mdi mdi-plus-circle-outline"></span
                      ></v-card-subtitle>
                    </v-card>
                  </v-col> -->
                </v-row>

                <v-row style="padding: 15px" v-if="home == '2'">
                  <v-col cols="12">
                    <v-row
                      style="display: flex; justify-content: space-between"
                    >
                      <v-col>
                        <div>
                          <span
                            class="mdi mdi-clock-time-seven-outline"
                            style="font-size: 40px"
                          ></span
                          ><span style="font-size: 40px; padding-left: 10px">{{
                            currentTime
                          }}</span>
                        </div>
                      </v-col>
                      <v-col style="display: flex; justify-content: flex-end">
                        <v-card
                          v-if="inOut == '1'"
                          class="flip-vertical-right"
                          elevation="5"
                          width="100"
                          height="100"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            background-color: #22577a;
                            color: white;
                            cursor: pointer;
                          "
                          @click="inOutStatus(inOut)"
                        >
                          {{ "ทางเข้า" }}<br />{{ "(Entry)" }}
                        </v-card>
                        <v-card
                          v-if="inOut == '2'"
                          class="flip-vertical-left"
                          elevation="5"
                          width="100"
                          height="100"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            background-color: #7f0000;
                            color: white;
                            cursor: pointer;
                          "
                          @click="inOutStatus(inOut)"
                        >
                          {{ "ทางออก" }}<br />{{ "(Exit)" }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-if="inOut == '1'">
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="licenseplate"
                          label="ทะเบียนรถ (Licence Plate)"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          type="number"
                          v-model="weightin"
                          label="น้ำหนักเข้า (Weights)"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-btn height="55px" color="primary" @click="entry()">
                          Done
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="inOut == '2'">
                      <v-col cols="12" sm="3" md="3">
                        <!-- <v-text-field
                          v-model="licenseplate"
                          label="ค้นหาทะเบียนรถ (Licence Plate)"
                          outlined
                        ></v-text-field> -->
                        <v-autocomplete
                          v-model="licenceSearch"
                          :items="filteredItems"
                          item-text="licenseplate"
                          label="ค้นหาทะเบียนรถ (Licence Plate)"
                          outlined
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="1" md="1">
                        <v-btn
                          height="55px"
                          color="primary"
                          @click="Searchlicenseplate(licenceSearch)"
                        >
                          Search
                        </v-btn>
                      </v-col>

                      <v-row v-if="showdata">
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            v-model="dataitem.licenseplate"
                            label="ทะเบียนรถ (Licence Plate)"
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            type="number"
                            v-model="dataitem.weightin"
                            label="น้ำหนักเข้า (Weights)"
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            type="number"
                            v-model="weightout"
                            label="น้ำหนักออก (Weights)"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" md="3">
                          <v-btn
                            height="55px"
                            color="primary"
                            @click="Exit(dataitem.licenseplate)"
                          >
                            Done
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row style="padding: 15px" v-if="home == '3'">
                  <v-col cols="12">
                    <v-card elevation="0">
                      <v-card-title>
                        Report
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                      ></v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
    </div>

    <!-- Preview PDF dialog -->
    <v-dialog v-model="dialogPreviewPDF" max-width="800px" persistent>
      <v-card>
        <v-card-title class="headline">E-Tax Invoice Preview</v-card-title>
        <v-card-text>
          <div style="position: relative">
            <iframe
              :src="pdfPreviewUrl"
              type="application/pdf"
              class="pdf-Preview"
            ></iframe>
            <div @click.prevent @contextmenu.prevent class="pdf-overlay"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogPreviewPDF = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading dialog -->
    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import { eventBus } from "../../main";
// import { generatePDF } from "@/plugins/generateETaxInvoice.js";

export default {
  name: "Weighing",

  data() {
    const defaultForm = Object.freeze({
      save_log_mode: false,
      tax_invoice_no: "",
      name: "",
      tax_ID: "",
      address: "",
    });

    return {
      url: enurl.apiUrl,
      urlCarpark: enurl.apiCarparkUrl,
      loadingDialog: false,
      currentTime: "",
      inOut: "1",
      // Customer input form
      form: Object.assign({}, defaultForm),
      defaultForm,

      // PDF file
      dialogPreviewPDF: false,
      pdfPreviewUrl: null,

      home: "1",
      title: "",
      entrydate: "",
      exitdate: "",
      licenseplate: "",
      licenceSearch: "",
      weightin: "",
      weightout: "",

      showdata: false,
      dataitem: [],

      items: [
        {
          title: "เครื่องชั่งที่ 1",
          description: "ทางเข้าทิศเหนือ",
          inOut: "1",
        },
        {
          title: "เครื่องชั่งที่ 2",
          description: "ทางออกทิศเหนือ",
          inOut: "2",
        },
        {
          title: "เครื่องชั่งที่ 3",
          description: "ทางเข้าทิศใต้",
          inOut: "1",
        },
        {
          title: "เครื่องชั่งที่ 4",
          description: "ทางออกทิศใต้",
          inOut: "2",
        },
      ],

      search: "",
      headers: [
        {
          text: "ทะเบียน",
          align: "start",
          sortable: false,
          value: "licenseplate",
        },
        { text: "เวลาเข้า", value: "entrydate" },
        { text: "เวลาออก", value: "exitdate" },
        { text: "ระยะเวลา (HH : MM : SS)", value: "duration" },
        { text: "น้ำหนักเข้า", value: "weightin" },
        { text: "น้ำหนักออก", value: "weightout" },
        { text: "ผลต่าง", value: "Difference" },
      ],
      desserts: [],
    };
  },

  computed: {
    // Dynamically filter items based on the search query
    filteredItems() {
      return this.desserts.filter((x) => x.status == 0);
    },
  },

  mounted() {
    let permission = localStorage.getItem("Permision");
    if (permission == null || permission == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(permission).find(
      (x) => x.appName == page
    );
  },

  created() {
    this.updateTime(); // อัปเดตเวลาเมื่อคอมโพเนนต์ถูกสร้างขึ้น
    this.timer = setInterval(this.updateTime, 1000); // อัปเดตทุกวินาที
  },
  beforeDestroy() {
    clearInterval(this.timer); // ล้าง interval เมื่อคอมโพเนนต์ถูกทำลาย
  },

  methods: {
    Searchlicenseplate(data) {
      let that = this;
      that.showdata = false;
      that.dataitem = that.desserts.find((x) => x.licenseplate == data && x.status == 0);
      if (that.dataitem != null) {
        that.showdata = true;
        console.log(that.dataitem);
      } else {
        console.log("null");
        that.showdata = false;
      }
    },
    entry() {
      let that = this;
      if (
        this.licenseplate == null ||
        this.licenseplate == "" ||
        this.weightin == null ||
        this.weight == ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Please enter a licenseplate and a weight",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let dataitem = that.desserts.find(
          (x) => x.licenseplate == this.licenseplate && x.status == 0
        );
        if (dataitem != null) {
          Swal.fire({
            icon: "error",
            title: "Duplicate licenseplate",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let temp = {
            id: 1,
            licenseplate: this.licenseplate,
            entrydate: this.currentTime,
            exitdate: "",
            duration: "",
            weightin: this.weightin,
            weightout: 0,
            Difference: 0,
            terminal: this.title,
            status: 0,
          };

          this.desserts.push(temp);

          this.licenseplate = "";
          this.weightin = "";

          Swal.fire({
            icon: "success",
            title: "Done",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    Exit(data) {
      if (this.weightout == null || this.weightout == "") {
        Swal.fire({
          icon: "error",
          title: "Please enter a weight",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let datafind = this.desserts.filter((d) => d.licenseplate == data && d.status == 0);

        datafind[0].exitdate = this.currentTime;
        datafind[0].status = 1;
        datafind[0].weightout = this.weightout;
        datafind[0].Difference = Math.abs(
          parseInt(datafind[0].weightin, 10) -
            parseInt(datafind[0].weightout, 10)
        );

        let date1 = new Date(datafind[0].entrydate); // Jan 1, 2025, 12:00 PM
        let date2 = new Date(datafind[0].exitdate);

        let diffInMs = date2 - date1;

        let seconds = diffInMs / 1000; // Seconds
        let minutes = seconds / 60; // Minutes
        let hours = minutes / 60; // Hours

        datafind[0].duration =
          Math.floor(hours % 24) +
          ":" +
          Math.floor(minutes % 60) +
          ":" +
          Math.floor(seconds % 60);

        this.weightout = "";
        this.showdata = false;
        Swal.fire({
          icon: "success",
          title: "Done",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleString("th-TH"); // รูปแบบวันที่และเวลาที่เหมาะสมกับ locale
    },

    inOutStatus(inOut) {
      if (inOut == "1") {
        this.inOut = "2";
        this.licenceSearch = "";
        this.showdata = false;
      } else {
        this.licenseplate = "";
        this.weightin = "";
        this.inOut = "1";
        
      }
    },

    async getDataWeighing(item) {
      let that = this;
      that.title = item.title;
      that.inOut = item.inOut;
      that.home = "2";
    },

    async backhome() {
      let that = this;
      that.title = "";
      that.home = "1";
      that.showdata = false;
      that.licenceSearch = "";
    },
    async report() {
      let that = this;
      that.title = "";
      that.home = "3";
    },
  },
};
</script>

<style>
.v-card-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.flip-vertical-right {
  -webkit-animation: flip-vertical-right 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

.flip-vertical-left {
  -webkit-animation: flip-vertical-left 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}

.pdf-Preview {
  width: 100%;
  height: 500px;
  border: none;
  cursor: not-allowed;
}

.pdf-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
