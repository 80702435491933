<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Redemption Users Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="mt-3">
                  <v-autocomplete
                    :items="tenantArray"
                    item-value="tenantID"
                    item-text="tenantName"
                    label="Tenant"
                    v-model="TenantID"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="mt-3">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="4" class="mt-1" align="right">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="SearchDataUser()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="green"
                    class="ma-2 white--text"
                    @click="OpenAddDataDialog()"
                  >
                    Create
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    color="orange"
                    class="ma-2 white--text"
                    @click="ExportDataUserRedemption()"
                  >
                    Export CSV
                    <v-icon right>mdi-file-chart-outline</v-icon>
                  </v-btn> -->
                  <vue-excel-xlsx
                    :data="dataExport"
                    :columns="columns"
                    :file-name="'Transaction User Redemption' + Date.now()"
                    :file-type="'xlsx'"
                    :sheet-name="'Transaction User Redemption'"
                  >
                    <v-btn color="orange" class="ma-2 white--text">
                      Export Excel
                      <v-icon right>mdi-file-excel-outline</v-icon>
                    </v-btn>
                  </vue-excel-xlsx>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="UserList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.username }}</td>
                    <td class="header-table-css">{{ item.email }}</td>
                    <td class="header-table-css">
                      {{ ConvertTenant(item.tenantId) }}
                    </td>
                    <td class="header-table-css">
                      {{ ConvertDepartment(item.departmentId) }}
                    </td>
                    <td style="text-align: center">
                      {{ ConvertRedeemType(item.redempType) }}
                    </td>
                    <td align="center">
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 100px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == "Active" ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenEditDataDialog(item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="InActiveRedemptionUser(item)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="AddDataDialog" persistent width="870">
      <v-card>
        <v-card-title
          style="background-color: black; color: white; padding: 13px"
        >
          <span class="text-h5">Create Redemption User</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-row class="ml-5 mr-5" style="margin-top: -2%">
            <v-col col="12" md="4">
              <p class="Title">Username <span style="color: red">*</span></p>
              <v-text-field dense outlined v-model="AddUsername"></v-text-field>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">Password <span style="color: red">*</span></p>
              <v-text-field
                dense
                outlined
                type="password"
                id="Addpassword"
                v-model="AddPassword"
                required
                :append-icon="flagPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="ToggleAddPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">FirstName</p>
              <v-text-field
                dense
                outlined
                v-model="AddFirstname"
              ></v-text-field>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">LastName</p>
              <v-text-field dense outlined v-model="AddLastname"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">Tenant <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="AddTenant"
                :items="TenantList"
                item-text="tenantName"
                item-value="tenantID"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">Department <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="AddDepartment"
                :items="DepartmentList"
                item-text="departmentName"
                item-value="departmentID"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">ประเภท Rete <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="AddStampType"
                :items="StampTypeList"
                item-text="redempType_Name"
                item-value="redempType_Id"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">ระดับผู้ใช้ <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="AddUserLevel"
                :items="UserStampLevelList"
                item-text="userLevel_Name"
                item-value="userLevel_Id"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">Email <span style="color: red">*</span></p>
              <v-text-field
                dense
                outlined
                type="email"
                id="Email"
                v-model="AddEmail"
              ></v-text-field>
            </v-col>
            <v-col md="6" col="12">
              <p class="Title">สถานะ <span style="color: red">*</span></p>
              <v-radio-group row v-model="AddStatus">
                <v-radio label="Active" value="1"></v-radio>
                <v-radio label="InActive" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>

        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%; margin-left: 3%"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddRedemptionUser()"
            >Add</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9"
            max-height="35"
            text
            @click="CloseAddDataDialog()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="EditDataDialog" persistent width="870">
      <v-card>
        <v-card-title
          style="background-color: black; color: white; padding: 3px"
        >
          <v-col cols="6" sm="6" md="6" style="text-align: start">
            <span class="text-h5">Edit Redemption User</span>
          </v-col>
          <v-col cols="6" sm="6" md="6" style="text-align: end">
            <v-tooltip bottom color="white" v-if="CheckEditStatus">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon color="white" v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <v-row>
                <v-col cols="12" sm="12" md="12" style="font-size: 15px; color: black">
                  Create By : {{ createBy }} &nbsp; {{ ChangeFormatDate(createTime) }}<br />
                  Update By : {{ updateBy }} &nbsp; {{ ChangeFormatDate(updateTime) }}<br />
                </v-col>
              </v-row>
            </v-tooltip>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-row class="ml-5 mr-5" style="margin-top: -2%">
            <v-col col="12" md="4">
              <p class="Title">Username <span style="color: red">*</span></p>
              <v-text-field
                dense
                outlined
                v-model="EditUsername"
              ></v-text-field>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">Password <span style="color: red">*</span></p>
              <v-text-field
                dense
                outlined
                type="password"
                id="Editpassword"
                v-model="EditPassword"
                required
                :append-icon="flagPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="ToggleEditPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">FirstName</p>
              <v-text-field
                dense
                outlined
                v-model="EditFirstname"
              ></v-text-field>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">LastName</p>
              <v-text-field
                dense
                outlined
                v-model="EditLastname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">Tenant <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="EditTenant"
                :items="TenantList"
                item-text="tenantName"
                item-value="tenantID"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">Department <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="EditDepartment"
                :items="DepartmentList"
                item-text="departmentName"
                item-value="departmentID"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">ประเภท Rete <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="EditStampType"
                :items="StampTypeList"
                item-text="redempType_Name"
                item-value="redempType_Id"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
            <v-col col="12" md="6">
              <p class="Title">ระดับผู้ใช้ <span style="color: red">*</span></p>
              <v-autocomplete
                dense
                outlined
                v-model="EditUserLevel"
                :items="UserStampLevelList"
                item-text="userLevel_Name"
                item-value="userLevel_Id"
                menu-props="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ml-5 mr-5" style="margin-top: -3%">
            <v-col col="12" md="6">
              <p class="Title">Email <span style="color: red">*</span></p>
              <v-text-field
                dense
                outlined
                type="email"
                id="Email"
                v-model="EditEmail"
              ></v-text-field>
            </v-col>
            <v-col md="6" col="12">
              <p class="Title">สถานะ <span style="color: red">*</span></p>
              <v-radio-group row v-model="EditStatus">
                <v-radio label="Active" value="1"></v-radio>
                <v-radio label="InActive" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <hr class="ml-4 mr-4" />
        <v-card-actions
          style="margin-right: 3%; margin-left: 3%"
          class="pb-6 mt-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateRedempUser()"
            >Update</v-btn
          >
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            style="background-color: #b9c1c9"
            max-height="35"
            text
            @click="CloseEditDataDialog()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "RedemptionManagement",
  components: {
    AlertComponent,
  },
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20, 50, 100],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      tenant: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      AddDataDialog: false,
      EditDataDialog: false,
      flagPassword: false,
      flagAddPassword: false,
      flagEditPassword: false,

      TenantID: null,
      activeSearch: null,
      UserList: [],
      TenantList: [],
      StampTypeList: [],
      DepartmentList: [],
      UserStampLevelList: [],

      AddUsername: "",
      AddPassword: "",
      AddEmail: "",
      AddFirstname: "",
      AddLastname: "",
      AddTenant: null,
      AddDepartment: "0",
      AddStampType: 1,
      AddUserLevel: 0,
      AddStatus: null,

      ID: null,
      EditUsername: "",
      EditPassword: "",
      EditEmail: "",
      EditFirstname: "",
      EditLastname: "",
      EditTenant: null,
      EditDepartment: null,
      EditStampType: null,
      EditUserLevel: null,
      EditStatus: null,

      CheckEditStatus: false,
      createBy: "",
      createTime: "",
      updateBy: "",
      updateTime: "",

      permissionsPage: [],
      dataExport: [],
      tenantArray: [],

      columns: [
        {
          label: "",
          field: "username",
        },
        {
          label: "",
          field: "firstname",
        },
        {
          label: "",
          field: "lastname",
        },
        {
          label: "",
          field: "email",
        },
        {
          label: "",
          field: "tenant",
        },
        {
          label: "",
          field: "active",
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Username",
          align: "left",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          sortable: false,
        },
        {
          text: "Tenant",
          align: "left",
          sortable: false,
        },
        {
          text: "Department",
          align: "left",
          sortable: false,
        },
        {
          text: "RedemptionType",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    await self.getDataRedemptionUser();
    await this.getTennant();
    await self.GetDataTenantInRedemption();
    await self.GetDataStampType();
    await self.GetDataDepartment();
    await self.GetDataUserStampLevel();
    await self.ExportDataUserRedemption();
  },

  methods: {
    getTennant() {
      let self = this;
      axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async getDataRedemptionUser() {
      let self = this;
      // self.page = 1;
      let temp = {
        tenantID: self.TenantID == null ? null : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
        search: self.search,
        page: self.page,
        perpage: 10,
      };
      self.LoadingDialog = true;
      await axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            // self.pageCount = response.data.data.count;
            // self.no_run = (self.page - 1) * self.itemsPerPage;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: 10,
        search: self.search,
        tenantID: self.TenantID == null ? null : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            // self.no_run = (self.page - 1) * self.itemsPerPage;
            // self.pageCount = response.data.data.count;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search,
        tenantID: self.TenantID == null ? null : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            // self.no_run = (self.page - 1) * self.itemsPerPage;
            // self.pageCount = response.data.data.count;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchDataUser() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: 10,
        search: self.search == null ? "" : self.search,
        tenantID: self.TenantID == null ? null : self.TenantID,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/getDataRedemptionUser`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserList = response.data.data.listData;
            //self.pageCount = response.data.data.count;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.page = 1;
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ExportDataUserRedemption() {
      let self = this;
      // self.LoadingDialog = true;
      let temp = {
        search: self.search,
        active:
          self.activeSearch == "Active"
            ? 0
            : self.activeSearch == "InActive"
            ? 1
            : null,
      };
      axios
        .post(`${self.url}Redemption/exportDataUserRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.LoadingDialog = false;
            self.dataExport = response.data.data;
            // var tempdata = response.data.data;
            // const blob = self.base64ToBlob(tempdata, "text/csv;charset=utf-8;");
            // const linkSource = URL.createObjectURL(blob);
            // var link = document.createElement("a");
            // var date = new Date();
            // link.download =
            //   "UserRedemptionReport_" + date.toLocaleString("th-TH") + ".csv";
            // link.href = linkSource;
            // link.click();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          // self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },

    async GetDataTenantInRedemption() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataTenantInRedemption`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TenantList = response.data.data;
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async GetDataStampType() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataStampType`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.StampTypeList = response.data.data;
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async GetDataDepartment() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataDepartment`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DepartmentList = response.data.data;
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async GetDataUserStampLevel() {
      let self = this;
      await axios
        .get(`${self.url}Redemption/GetDataUserStampLevel`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.UserStampLevelList = response.data.data;
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    OpenEditDataDialog(data) {
      let self = this;
      let temp = {
        id: data.id,
      };
      self.LoadingDialog = true;
      axios
        .post(`${self.url}Redemption/getDataRedemptionUserByID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.BindingEditDataUserRedemption(response.data.data);
            self.LoadingDialog = false;
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    BindingEditDataUserRedemption(data) {
      let self = this;
      self.ID = data.id;
      self.EditUsername = data.username;
      self.EditPassword = data.password;
      self.EditFirstname = data.firstname;
      self.EditLastname = data.lastname;
      self.EditTenant = data.tenantID;
      self.EditEmail = data.email;
      self.EditDepartment = data.departmentID.toString();
      self.EditStampType = data.redemptionType;
      self.EditUserLevel = data.userLevel;
      self.EditStatus =
        data.active == true ? "1" : data.active == false ? "2" : null;

      self.createBy = data.createBy;
      self.createTime = data.createTime;
      self.updateBy = data.updateBy;
      self.updateTime = data.updateTime;
      self.CheckEditStatus = true;
      self.EditDataDialog = true;
    },

    ConvertTenant(data) {
      let self = this;
      var result = self.TenantList.filter((x) => x.tenantID == parseInt(data));
      if (result.length > 0) {
        return result[0].tenantName;
      }
      return "";
    },

    ConvertDepartment(data) {
      let self = this;
      var result = self.DepartmentList.filter((x) => x.departmentID == data);
      if (result.length > 0) {
        return result[0].departmentName;
      }
      return "";
    },

    ConvertRedeemType(data) {
      let self = this;
      var result = self.StampTypeList.filter(
        (x) => x.redempType_Id == parseInt(data)
      );
      if (result.length > 0) {
        return result[0].redempType_Name;
      }
      return "";
    },

    GetColor(value) {
      if (value == "Active") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == "Active") {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    ToggleAddPassword() {
      let self = this;
      var x = document.getElementById("Addpassword");
      if (x.type === "password") {
        x.type = "text";
        self.flagAddPassword = false;
      } else {
        x.type = "password";
        self.flagAddPassword = true;
      }
    },

    ToggleEditPassword() {
      let self = this;
      var x = document.getElementById("Editpassword");
      if (x.type === "password") {
        x.type = "text";
        self.flagEditPassword = false;
      } else {
        x.type = "password";
        self.flagEditPassword = true;
      }
    },

    OpenAddDataDialog() {
      let self = this;
      self.CloseAddDataDialog();
      self.AddDataDialog = true;
    },

    CloseAddDataDialog() {
      let self = this;
      self.AddUsername = "";
      self.AddPassword = "";
      self.AddFirstname = "";
      self.AddLastname = "";
      self.AddEmail = "";
      self.AddTenant = null;
      self.AddDepartment = "0";
      self.AddStampType = 1;
      self.AddUserLevel = 0;
      self.AddStatus = "1";
      self.AddDataDialog = false;
    },

    CloseEditDataDialog() {
      let self = this;
      self.EditUsername = "";
      self.EditPassword = "";
      self.EditFirstname = "";
      self.EditLastname = "";
      self.EditEmail = "";
      self.EditTenant = null;
      self.EditDepartment = null;
      self.EditStampType = null;
      self.EditUserLevel = null;
      self.EditStatus = null;
      self.EditDataDialog = false;
    },

    AddRedemptionUser() {
      let self = this;
      console.log("AddRedemptionUser");
      if (
        self.AddUsername == "" ||
        self.AddPassword == "" ||
        self.AddTenant == null ||
        self.AddDepartment == null ||
        self.AddEmail == "" ||
        self.AddStampType == null ||
        self.AddStatus == null || 
        self.AddUserLevel === "" ||
        self.AddUserLevel == null
      ) {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      } else {
        let temp = {
          username: self.AddUsername,
          password: self.AddPassword,
          email: self.AddEmail,
          firstname: self.AddFirstname,
          lastname: self.AddLastname,
          tenantID: self.AddTenant,
          department:
            self.AddDepartment == null ? null : parseInt(self.AddDepartment),
          stamptype: self.AddStampType,
          userlevel: self.AddUserLevel,
          status:
            self.AddStatus == "1" ? true : self.AddStatus == "2" ? false : null,
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}Redemption/AddRedemptionUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.page = 1;
            self.getDataRedemptionUser();
            self.AddDataDialog = false;
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    UpdateRedempUser() {
      let self = this;
      if (
        self.EditUsernameUsername == "" ||
        self.EditPassword == "" ||
        self.EditTenant == null ||
        self.EditDepartment == null ||
        self.EditEmail == "" ||
        self.EditStampType == null ||
        self.EditStatus == null ||
        self.EditUserLevel === "" ||
        self.EditUserLevel == null
      ) {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      } else {
        let temp = {
          id: self.ID,
          username: self.EditUsername,
          password: self.EditPassword,
          email: self.EditEmail,
          firstname: self.EditFirstname,
          lastname: self.EditLastname,
          tenantID: self.EditTenant,
          department:
            self.EditDepartment == null ? null : parseInt(self.EditDepartment),
          stamptype: self.EditStampType,
          userlevel: self.EditUserLevel,
          status:
            self.EditStatus == "1"
              ? true
              : self.EditStatus == "2"
              ? false
              : null,
        };
        self.LoadingDialog = true;
        axios
          .post(`${self.url}Redemption/UpdateRedemptionUser`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            self.getDataRedemptionUser();
            self.EditDataDialog = false;
            self.LoadingDialog = false;
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    InActiveRedemptionUser(data) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        id: data.id,
      };
      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${self.url}Redemption/InActiveRedemptionUser`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              self.getDataRedemptionUser();
              self.LoadingDialog = false;
            })
            .catch(function (error) {
              self.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          self.LoadingDialog = false;
        }
        self.LoadingDialog = false;
      });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  /* cursor: pointer; */
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
